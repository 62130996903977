import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Search from './Search';
import { HorizontalLine } from '../components';
import { ConfigProdCategoryTree } from './ConfigProdCategoryTree';
import ConfigProductResults from './ConfigProductResults';

const ConfigProductBrowser = (props) => {
    const { show, tag, projectId, saveHandler, onClose } = props;
    const [activeZone, setActiveZone] = useState(null);
    const [filters, setFilters] = useState(false);
    const [breadCrumb, setBreadCrumb] = useState([
        { title: 'Categories', details: '' },
    ]);

    const title = useMemo(() => {
        return activeZone ? activeZone.title : 'Categories';
    }, [activeZone]);

    const initBreadcrumb = () => {
        setBreadCrumb([{ title: 'Categories', details: '' }]);
    };

    const addBreadcrumb = (breadCrumb) => {
        setBreadCrumb((prevBreadCrumb) => [...prevBreadCrumb, breadCrumb]);
    };

    useEffect(() => {
        setBreadCrumb([{ title: 'Categories', details: '' }]);
    }, [show]);

    const displayProducts = (updatedFilters) => {
        setFilters(updatedFilters);
    };

    const hideProducts = () => {
        setFilters(false);
        setActiveZone(null);
    };

    const handleChange = useCallback(
        (zoneDetails) => {
            setActiveZone(zoneDetails);

            if (
                zoneDetails.title !== activeZone?.title &&
                zoneDetails.type !== 'search'
            ) {
                addBreadcrumb(zoneDetails);
            }

            if (zoneDetails.type === 'search') {
                initBreadcrumb();
            }
        },
        [activeZone],
    );

    const handleBreadCrumb = useCallback(
        (zone) => {
            hideProducts();

            const itemIndex = breadCrumb.findIndex(
                (item) => item.title === zone.title,
            );

            if (itemIndex !== -1) {
                const newBreadcrumbs = breadCrumb.slice(0, itemIndex + 1);
                setBreadCrumb(newBreadcrumbs);
            }

            setActiveZone(zone);
        },
        [hideProducts, breadCrumb, setBreadCrumb],
    );

    return (
        <div className="bg-white border-l border-r border-t border-black-10 p-5 min-w-[1100px] min-h-[700px]">
            <div className="p-5 border border-black-5 -mb-5">
                <div className="text-xl font-semibold text-gray-800">
                    Add Product
                </div>
                <HorizontalLine />
                <div className="font-bold text-sm">Product Search</div>
            </div>
            <div className="p-5 border border-black-5">
                <div className="text-xl font-semibold text-gray-800">
                    {title}
                </div>
                {breadCrumb.length >= 2 ? (
                    <div className="flex items-center space-x-2 breadcrumbs my-2">
                        {breadCrumb.map((item, idx) => (
                            <React.Fragment key={item.title}>
                                <button
                                    className="underline enabled:text-green-dark disabled:text-black-20"
                                    key={item.title}
                                    onClick={() => {
                                        handleBreadCrumb(item);
                                    }}
                                    disabled={item === activeZone}
                                >
                                    {item.title}
                                </button>
                                {idx + 1 !== breadCrumb.length ? (
                                    <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                                ) : null}
                            </React.Fragment>
                        ))}
                    </div>
                ) : null}
                {filters === false ? (
                    <div className="category-wrapper">
                        <ConfigProdCategoryTree
                            onFilter={displayProducts}
                            onChange={handleChange}
                            activeZone={activeZone}
                        />
                    </div>
                ) : (
                    <div>
                        <ConfigProductResults
                            previousZone={activeZone}
                            tag={tag}
                            projectId={projectId}
                            currZone={activeZone}
                            saveHandler={saveHandler}
                            onClose={onClose}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConfigProductBrowser;
