import React, {
    Fragment,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react';
import * as storeExt from '../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HorizontalLine } from '../components';

const { useGetCategoriesQuery } = storeExt;

const CategoryTree = ({ onFilter, onChange, activeZone }) => {
    const { data: categories, isLoading } = useGetCategoriesQuery();
    const [categoryList, setCategoryList] = useState(null);
    const [isSubcategory, setIsSubcategory] = useState(null);

    const buildCategoryTree = (categories, parentId = null) => {
        const categoryMap = {};
        const rootCategories = [];

        categories.forEach((category) => {
            categoryMap[category.id] = { ...category, children: [] };
        });

        categories.forEach((category) => {
            if (category.parentId === parentId) {
                const childCategory = categoryMap[category.id];
                const nestedChildren = buildCategoryTree(
                    categories,
                    category.id,
                );
                if (nestedChildren.length > 0) {
                    childCategory.children = nestedChildren;
                }
                rootCategories.push(childCategory);
            }
        });

        return rootCategories;
    };
    const loadCategory = useCallback(
        (category) => {
            if (category) {
                setCategoryList(category.children);
            }
        },
        [setCategoryList, categories],
    );

    const loadFirstLevel = useCallback(() => {
        if (!isLoading && categories) {
            const categoryTree = buildCategoryTree(categories);
            setCategoryList(categoryTree);
        }
        setIsSubcategory(false);
    }, [loadCategory, setIsSubcategory]);

    const loadSubCategory = useCallback(
        (category) => {
            setIsSubcategory(true);
            loadCategory(category);
        },
        [loadCategory, setIsSubcategory],
    );

    const handleFirstLevelClick = useCallback(
        (category) => {
            return () => {
                onChange({
                    type: 'category',
                    details: category.id,
                    title: category.name,
                    category: category,
                });
                loadSubCategory(category);
            };
        },
        [onChange, loadSubCategory],
    );

    const handleSubcategoryClick = useCallback(
        (category) => {
            return () => {
                onChange({
                    type: 'category',
                    details: category.id,
                    title: category.name,
                    category: category,
                });
                onFilter({
                    category: category,
                });
                setIsSubcategory(true);
            };
        },
        [onChange, onFilter, setIsSubcategory],
    );

    const displayedCategories = useMemo(() => {
        if (!categoryList) {
            return null;
        }

        if (isSubcategory) {
            return (
                <div className="flex flex-wrap">
                    {categoryList.map((category) => (
                        <div
                            className="flex max-w-[300px] p-2 "
                            key={category.id}
                            onClick={handleSubcategoryClick(category)}
                        >
                            <div className="border border-black-10 p-2">
                                <div className="p-2">
                                    {category.image && (
                                        <img
                                            src={category.image}
                                            alt={category.name}
                                            title={category.name}
                                            className="w-[300px] h-auto rounded-lg"
                                        />
                                    )}
                                </div>
                                <div className="text-md text-center font-semibold justify-center p-2">
                                    {category.name}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        return categoryList.map((category) => {
            return (
                <div key={category.id}>
                    <HorizontalLine />
                    <div
                        onClick={handleFirstLevelClick(category)}
                        className="flex items-center first-level-item"
                    >
                        <FontAwesomeIcon
                            icon="fa-solid fa-angle-right"
                            className="mr-2"
                        />
                        <div className="list-item-text text-green-dark underline">
                            {category.name}
                        </div>
                    </div>
                </div>
            );
        });
    }, [
        categoryList,
        isSubcategory,
        handleFirstLevelClick,
        handleSubcategoryClick,
    ]);

    useEffect(() => {
        if (!isLoading && categories) {
            const categoryTree = buildCategoryTree(categories);
            setCategoryList(categoryTree);
        }
    }, [isLoading, categories]);

    useEffect(() => {
        if (
            activeZone &&
            activeZone.type === 'category' &&
            activeZone.category
        ) {
            loadSubCategory(activeZone.category);
        } else {
            loadFirstLevel();
        }
    }, [activeZone]);

    return (
        <Fragment>
            <div className="category-tree">{displayedCategories}</div>
        </Fragment>
    );
};

export default CategoryTree;
