import React, { useMemo, useState } from 'react';
import * as storeExt from '../store';
import { UserDetailTabs } from '../components/UserDetailTabs';
import classNames from 'classnames';

const {
    useGetUsersQuery,
} = storeExt;

const UserItem = (props) => {
    const { user, selectedUser, setSelectedUser } = props;

    const isSelected = useMemo(() => {
        if (selectedUser === user.id) {
            return true;
        }
        return false;
    }, [user.id, selectedUser]);

    const onClickHandler = (isSelected, selectedUserId) => {
        if (!isSelected) {
            setSelectedUser(selectedUserId);
        } else {
            setSelectedUser(null);
        }
    };
    
    return (
        <tr className={classNames({ 'text-white bg-green-dark': isSelected })}>
            <td
                className="px-6 py-2 align-middle"
                onClick={() => {
                    onClickHandler(isSelected, user.id);
                }}
            >
                {user.name}
            </td>
        </tr>
    );
};

const UserTableHeader = () => {
    return (
        <thead className="text-black-30 py-4">
            <tr>
                <th className="pt-2 pb-4 align-middle font-bold text-left px-4">User Name</th>
            </tr>
        </thead>
    );
};

const UserTableBody = (props) => {
    const { users, selectedUser, setSelectedUser } = props;

    if (users !== null && users !== undefined) {
        return (
            <tbody>
                { users.map((user) => (
                    <UserItem
                        key={user.id}
                        user={user}
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                    />
                ))}
            </tbody>
        );
    }
    return null;
};

const UserTable = (props) => {
    const { users, selectedUser, setSelectedUser } = props;

    return (
        <table className="w-full border-collapse">
            <UserTableHeader />
            <UserTableBody
                users={users}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
            />
        </table>
    );
};

export const User = () => {
    const { data: users } = useGetUsersQuery();
    const [selectedUserId, setSelectedUserId] = useState(null);

    return (
        <div className="flex gap-6 h-full">
            <div className="bg-white w-1/4 overflow-auto">
                <div className="p-2">
                    <UserTable
                        users={users}
                        selectedUser={selectedUserId}
                        setSelectedUser={setSelectedUserId}
                    />
                </div>
            </div>
            <div className="w-1/2">
                <UserDetailTabs
                    selectedUser={selectedUserId}
                    setSelectedUser={setSelectedUserId}
                />
            </div>
        </div>
    );
};