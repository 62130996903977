import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HorizontalLine } from '../components';
import * as storeExt from '../store';
import lodashIsEqual from 'lodash/isEqual';
import lodashOrderBy from 'lodash/orderBy';
import lodashFilter from 'lodash/filter';

const { useGetConfigQuery, useGetMeQuery } = storeExt;

export const ConfigProdCategoryTree = ({ onFilter, onChange, activeZone }) => {
    const { data: config } = useGetConfigQuery();
    const { data: currentUser } = useGetMeQuery();
    const [categoryList, setCategoryList] = useState(null);
    const [isSubcategory, setIsSubcategory] = useState(null);

    const userProductRoles = useMemo(() => {
        if (!currentUser?.roles) {
            return [];
        }
        return currentUser?.roles
            .map((role) => {
                return role.code;
            })
            .reduce(function (acc, curr) {
                const prefix = curr.substr(0, 10);
                const len = curr.length;
                const suffix = curr.substr(len - 8, 8);
                if (prefix === 'ADD_CONFIG' && suffix !== 'TAKE_OFF') {
                    acc.push(curr);
                }
                return acc;
            }, [])
    }, [currentUser?.roles]);

    const userAccessibleProducts = useMemo(() => {
        if (!config?.configProductMenu || userProductRoles.length === 0) {
            return [];
        }
        const filteredMenuEntries = lodashFilter(config?.configProductMenu, function (x) {
            return userProductRoles.includes(x.roleCode);
        });
        if (!filteredMenuEntries) {
            return [];
        }
        return filteredMenuEntries
            .map((item) => {
                return item.configId;
            })
            .reduce(function (acc, curr) {
                acc.push(curr);
                return acc;
            }, []);
    }, [userProductRoles, config?.configProductMenu]);

    const buildCategoryTree = (categories) => {
        let rootCategories = [];
        const filteredCategories = lodashFilter(categories, function (x) {
            return userAccessibleProducts.includes(x.configId);
        });

        if (filteredCategories) {
            const wip = filteredCategories
                .map((item) => {
                    return {
                        group: item.group,
                        groupOrdinal: item.groupOrdinal,
                        children: [],
                    };
                })
                .reduce(function (acc, curr) {
                    const copy = acc.find((element) =>
                        lodashIsEqual(element, curr),
                    );
                    if (!copy) acc.push(curr);
                    return acc;
                }, []);
            rootCategories = lodashOrderBy(wip, ['groupOrdinal'], ['asc']);
            rootCategories.forEach((category) => {
                const wip = lodashFilter(filteredCategories, [
                    'group',
                    category.group,
                ]);
                category.children = lodashOrderBy(wip, ['subOrdinal'], ['asc']);
            });
        }
        return rootCategories;
    };

    const loadCategory = useCallback(
        (category) => {
            if (category) {
                setCategoryList(category.children);
            }
        },
        [setCategoryList],
    );

    const loadFirstLevel = useCallback(() => {
        if (config) {
            const categoryTree = buildCategoryTree(config?.configProductMenu);
            setCategoryList(categoryTree);
        }
        setIsSubcategory(false);
    }, [setIsSubcategory, setCategoryList, config]);

    const loadSubCategory = useCallback(
        (category) => {
            setIsSubcategory(true);
            loadCategory(category);
        },
        [loadCategory, setIsSubcategory],
    );

    const handleFirstLevelClick = useCallback(
        (category) => {
            return () => {
                onChange({
                    type: 'category',
                    title: category.group,
                    category: category,
                });
                onFilter({
                    category: category.group,
                });
                setIsSubcategory(true);
            };
        },
        [onChange, onFilter, setIsSubcategory],
    );

    const displayedCategories = useMemo(() => {
        if (!categoryList) {
            return null;
        }

        if (isSubcategory) {
            return (
                <div className="flex flex-wrap">
                    {categoryList.map((category) => (
                        <div
                            className="flex max-w-[300px] p-2"
                            key={category.group}
                        >
                            <div className="border border-black-10 p-2">
                                <div className="text-md text-center font-semibold justify-center p-2">
                                    {category.group}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        return categoryList.map((category) => {
            return (
                <div key={category.group}>
                    <HorizontalLine />
                    <div
                        onClick={handleFirstLevelClick(category)}
                        className="flex items-center first-level-item"
                    >
                        <FontAwesomeIcon
                            icon="fa-solid fa-angle-right"
                            className="mr-2"
                        />
                        <div className="list-item-text text-green-dark underline">
                            {category.group}
                        </div>
                    </div>
                </div>
            );
        });
    }, [categoryList, isSubcategory]);

    useEffect(() => {
        if (config) {
            const categoryTree = buildCategoryTree(config?.configProductMenu);
            setCategoryList(categoryTree);
        }
    }, [config]);

    return (
        <Fragment>
            <div className="category-tree">{displayedCategories}</div>
        </Fragment>
    );
};
