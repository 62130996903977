import React from 'react';
import { useDispatch } from 'react-redux';
import {
    useCreateProjectTagItemMutation,
    useGetProjectQuery,
    useGetMeQuery,
    useGetConfigQuery,
    mainActions,
} from '../store';
import * as utils from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';

const ConfigProductResults = (props) => {
    const { tag, projectId, currZone, saveHandler, onClose } = props;

    const [, setCpqData] = utils.cpqDataState.useStateContext();
    const products = currZone?.category?.children;
    const { data: project } = useGetProjectQuery(projectId);
    const { data: currentUser } = useGetMeQuery();
    const { data: config } = useGetConfigQuery();
    const { extQuoteInfo } = project;

    const projectInfo = React.useMemo(() => {
        let currency = '';
        if (
            extQuoteInfo &&
            extQuoteInfo.currency &&
            extQuoteInfo.currency !== ''
        ) {
            currency = extQuoteInfo.currency;
        } else {
            currency =
                currentUser?.settings?.find((x) => x.key === 'currency')
                    ?.value ?? 'USD';
        }
        return { name: project.name, currency: currency };
    }, [project, extQuoteInfo, currentUser]);

    let displayedProducts = null;

    if (products) {
        displayedProducts = (
            <div className="grid grid-cols-2">
                {products.map((product) => (
                    <div
                        className="border-2 border-black-15 p-2 -m-[1px]"
                        key={`product-result-${uuidv4()}`}
                    >
                        <div
                            key={product.configId}
                            className="flex items-center"
                        >
                            <h2 className="text-sm text-black flex-grow">
                                {product.display}
                            </h2>
                            <button
                                className="swegon-like-button contained text-sm"
                                onClick={() => {
                                    onClose();
                                    setCpqData({
                                        showModal: true,
                                        configuratorId: product.configId,
                                        onSubmit: saveHandler,
                                        projectInfo: projectInfo,
                                        projectSiteInfoJson:
                                            project.siteInfoConfiguratorJson,
                                        onConfiguratorLoaded: (conf) => {
                                            switch (product.configId) {
                                                case config?.configurators.CTB:
                                                    conf.setFields({
                                                        'fEquipment Tag':
                                                            tag.value,
                                                    });
                                                    break;
                                                default:
                                                    conf.setFields({
                                                        fTag: tag.value,
                                                    });
                                                    break;
                                            }
                                        },
                                    });
                                }}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return <div>{displayedProducts}</div>;
};

export default ConfigProductResults;
