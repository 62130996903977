import React from 'react';
import ReactDOM from 'react-dom/client';
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import * as L from 'leaflet';
import {
    faArrowRightFromBracket,
    faXmark,
    faCircleInfo,
    faStar as faStarSolid,
    faMapLocationDot,
    faTag,
    faTags,
    faFilter,
    faEllipsis,
    faPen,
    faPlus,
    faCopy,
    faTrashCan,
    faEllipsisVertical,
    faAngleRight,
    faMagnifyingGlass,
    faArrowRight,
    faArrowLeft,
    faX,
    faChevronRight,
    faCircleChevronUp,
    faChevronUp,
    faCircleChevronDown,
    faChevronDown,
    faTriangleExclamation,
    faArrowUpFromBracket,
    faChevronLeft,
    faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';

import {
    faFolder,
    faStar as faStarRegular,
    faFile,
} from '@fortawesome/free-regular-svg-icons';

import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

import 'leaflet/dist/leaflet.css';

import './index.css';

import App from './App';

import reportWebVitals from './reportWebVitals';

// delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});

faLibrary.add(
    faArrowRightFromBracket,
    faXmark,
    faCircleInfo,
    faFolder,
    faStarRegular,
    faStarSolid,
    faMapLocationDot,
    faTag,
    faTags,
    faFilter,
    faEllipsis,
    faPen,
    faCopy,
    faTrashCan,
    faPlus,
    faEllipsisVertical,
    faAngleRight,
    faMagnifyingGlass,
    faX,
    faArrowLeft,
    faArrowRight,
    faFile,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faCircleChevronUp,
    faCircleChevronDown,
    faTriangleExclamation,
    faArrowUpFromBracket,
    faChevronLeft,
    faPenToSquare,
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
