import React from 'react';
import {
    LogLevel as SignalRLogLevel,
    HubConnectionBuilder,
} from '@microsoft/signalr';
import { hubConnectionWithMethods, registerHubHandlers } from './store';

// const WrappedConnection = (connection, startedPromise) => {
//     const invoker = {
//         invoke: async (method, ...args) => {
//             await startedPromise;
//             const res = await connection.invoke(method, ...args);
//             return res;
//         }
//     };

//     // invoker.invoke("auth/token");
//     return hubConnectionWithMethods(invoker);
// };

const HubConnectionContext = React.createContext(null);

export const useHubConnection = () => React.useContext(HubConnectionContext);

export const WithHubConnectionContext = (props) => {
    const { opts, children } = props;

    const connection = new HubConnectionBuilder()
        .withUrl(opts.url)
        .configureLogging(opts.logLevel ?? SignalRLogLevel.Information)
        .withAutomaticReconnect()
        .build();

    const connectionFuture = connection.start().then(() => connection);

    // registerHubHandlers(connection);
    return (
        <HubConnectionContext.Provider value={connectionFuture}>
            {children}
        </HubConnectionContext.Provider>
    );
};
