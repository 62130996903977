import React from 'react';
import {
    configureStore,
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import {
    createApi,
    fetchBaseQuery,
    setupListeners,
} from '@reduxjs/toolkit/query/react';

import { v4 as uuidv4 } from 'uuid';

const mainApi = createApi({
    reducerPath: 'mainApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/' }),
    tagTypes: ['User'],
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => 'users/all',
            providesTags: ['Users'],
        }),
        getMe: builder.query({
            query: () => 'users/me',
            providesTags: ['User'],
        }),
        getConfig: builder.query({
            query: () => 'config',
        }),
        updateMySettings: builder.mutation({
            query: (body) => ({
                url: 'users/me/settings',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['User'],
        }),
        getRoles: builder.query({
            query: () => 'roles/all',
            providesTags: ['Roles'],
        }),
        updateUser: builder.mutation({
            query: (body) => ({
                url: 'user/update',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Users', 'User'],
        })
    }),
});

const projectsApi = createApi({
    reducerPath: 'projectsApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/projects/' }),
    tagTypes: ['Project'],
    endpoints: (builder) => ({
        getProjects: builder.query({
            query: () => '',
            providesTags: ['Project'],
        }),
        getProject: builder.query({
            query: (id) => `${id}`,
            providesTags: (result, error, id) => [{ type: 'Project', id }],
        }),
        createProject: builder.mutation({
            query: (body) => ({
                url: '',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Project'],
        }),
        copyProject: builder.mutation({
            query: ({ id }) => ({
                url: `${id}/copy`,
                method: 'POST',
                
            }),
            invalidatesTags: ['Project'],
        }),
        updateProject: builder.mutation({
            query: ({ id, ...body }) => ({
                url: `${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Project'],
            // async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
            //     const { data: updatedProject } = await queryFulfilled;
            //     dispatch(
            //         projectsApi.util.updateQueryData('getProject', id, (draft) => {
            //             Object.assign(draft, updatedProject)
            //         })
            //     );
            // },
        }),
        deleteProject: builder.mutation({
            query: ({ id }) => ({
                url: `${id}`,
                method: 'DELETE',
                
            }),
            invalidatesTags: ['Project'],
            
        }),        
        createProjectTags: builder.mutation({
            query: ({ id, tags }) => ({
                url: `${id}/tags`,
                method: 'POST',
                body: tags,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),
        updateProjectTag: builder.mutation({
            query: ({ id, tag }) => ({
                url: `${id}/tags`,
                method: 'PUT',
                body: tag,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),
        deleteProjectTag: builder.mutation({
            query: ({ id, tag }) => ({
                url: `${id}/tags`,
                method: 'DELETE',
                body: tag,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),
        duplicateProjectTag: builder.mutation({
            query: ({ id, tagId, tags }) => ({
                url: `${id}/tags/${tagId}/copy`,
                method: 'POST',
                body: tags,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),
        createConfiguredItem: builder.mutation({
            query: ({ id, tag, productId }) => ({
                url: `${id}/tags/${productId}`,
                method: 'POST',
                body: tag,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),
        createProjectTagItem: builder.mutation({
            query: ({ id, tagId, qty, config }) => ({
                url: `${id}/tags/${tagId}/item`,
                method: 'POST',
                body: { quantity: qty, configuratorJson: config },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),

        createProjectTagTakeoff: builder.mutation({
            query: ({ id, tagId, qty, config }) => ({
                url: `${id}/tags/${tagId}/takeoff`,
                method: 'POST',
                body: { quantity: qty, configuratorJson: config },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),

        updateProjectTagItem: builder.mutation({
            query: ({ id, tagId, qty, config, itemId }) => ({
                url: `${id}/tags/${tagId}/item/${itemId}`,
                method: 'PUT',
                body: { quantity: qty, configuratorJson: config },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),
        deleteProjectTagItem: builder.mutation({
            query: ({ id, tagId, itemId }) => ({
                url: `${id}/tags/${tagId}/item/${itemId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),
        copyProjectTagItem: builder.mutation({
            query: ({ id, tagId, itemId }) => ({
                url: `${id}/tags/${tagId}/item/${itemId}/copy`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),
        updateProjectTagItemQty: builder.mutation({
            query: ({ id, tagId, qty, itemId}) => ({
                url: `${id}/tags/${tagId}/item/${itemId}/qty?qtyToSave=${qty}`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),
        buildItemDocument: builder.mutation({
            query: ({ id, tagId, itemId, buildType }) => ({
                url: `${id}/tags/${tagId}/item/${itemId}/document/build`,
                method: 'POST',
                body: { buildType },
            }),
            //            invalidatesTags: (result, error, arg) => [
            //                { type: 'Project', id: arg.id },
            //            ],
        }),
        buildQuoteDocument: builder.mutation({
            query: ({ id, docType }) => ({
                url: `${id}/documents/${docType}`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),
        saveValidation: builder.mutation({
            query: ({ id, itemNum, okItems, errorItems }) => ({
                url: `${id}/items/validation/save`,
                method: 'POST',
                body: {
                    itemsToCheck: itemNum,
                    resolveItems: okItems,
                    errorItems: errorItems,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id },
            ],
        }),
        uploadDrawings: builder.mutation({
            query: ({ projectId, body }) => ({
                url: `${projectId}/drawings/upload`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Drawing'],
        }),
        getDrawings: builder.query({
            query: (id) => `${id}/drawings`,
            providesTags: ['Drawing'],
        }),
        deleteDrawing: builder.mutation({
            query: ({ projectId, id }) => ({
                url: `${projectId}/drawings/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Drawing'],
        }),
        updateDrawing: builder.mutation({
            query: ({ projectId, id, label }) => ({
                url: `${projectId}/drawings/${id}/update?label=${window.encodeURIComponent(
                    label,
                )}`,
                method: 'POST',
            }),
            invalidatesTags: ['Drawing'],
        }),
        createOverlayMarker: builder.mutation({
            query: ({ projectId, drawingId, body }) => ({
                url: `${projectId}/drawings/${drawingId}/create-overlay-marker`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Drawing'],
        }),
        deleteOverlayMarker: builder.mutation({
            query: ({ projectId, drawingId, overlayMarkerId }) => ({
                url: `${projectId}/drawings/${drawingId}/overlay-markers/${overlayMarkerId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Drawing'],
        }),
    }),
});

const categoryApi = createApi({
    reducerPath: 'categoryApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/categories' }),
    tagTypes: ['Category'],
    endpoints: (builder) => ({
        getCategories: builder.query({
            query: () => '',
            providesTags: ['Category'],
        }),
        getCategory: builder.query({
            query: (id) => `${id}`,
            providesTags: (result, error, id) => [{ type: 'Category', id }],
        }),
        getCategoryProducts: builder.query({
            query: (id) => `${id}/products`,
            providesTags: (result, error, id) => [{ type: 'Category', id }],
        }),
        getProducts: builder.query({
            query: (search) => `products/${search}`,
            providesTags: (result, error, id) => [{ type: 'Product', id }],
        }),
        getProductsContainingString: builder.query({
            query: (fragment) => `products/search/${fragment}`,
            providesTags: (result, error, id) => [{ type: 'Product', id }],
        }),
    }),
});

export const documentsApi = createApi({
    reducerPath: 'documentsApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/documents/' }),
    tagTypes: ['Document', 'ItemPrinting', 'QuotePrinting'],
    endpoints: (builder) => ({
        getQuoteDocuments: builder.query({
            query: (id) => `quote/${id}`,
            providesTags: (result, error, id) => [{ type: 'Document', id }],
        }),
        getProductDocuments: builder.query({
            query: (id) => `item/${id}`,
            providesTags: (result, error, id) => [{ type: 'Document', id }],
        }),
        submitProductDocJob: builder.mutation({
            query: ({ id, tagId, itemId, buildType }) => ({
                url: `quote/${id}/tag/${tagId}/item/${itemId}/build`,
                method: 'POST',
                body: { buildType },
            }),
        }),
        submitQuoteDocJob: builder.mutation({
            query: ({ id, docType }) => ({
                url: `quote/${id}/build/${docType}`,
                method: 'POST',
            }),
        }),
        deleteProductDocument: builder.mutation({
            query: ({id, tagId, itemId, docId, seqId}) => ({
                url: `item/${itemId}/doc/${docId}`,
                method: 'PUT',
                body: { quoteId: id, tagId: tagId, seqId: seqId },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Document', id: arg.itemId }
            ],
        }),
        deleteQuoteDocument: builder.mutation({
            query: ({ id, docId, seqId }) => ({
                url: `quote/${id}/doc/${docId}`,
                method: 'PUT',
                body: { seqId: seqId },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Document', id: arg.id}
            ],
        }),
        getProductHasJobInProgress: builder.query({
            query: (id) => `item/${id}/stillprinting`,
            providesTags: (result, error, id) => [{ type: 'ItemPrinting', id }],
        }),
        getQuoteHasJobInProgress: builder.query({
            query: (id) => `quote/${id}/stillprinting`,
            providesTags: (result, error, id) => [{ type: 'QuotePrinting', id }],
        }),
    }),
});

const cpqApi = createApi({
    reducerPath: 'cpqApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/cpq/' }),
    tagTypes: ['Cpq'],
    endpoints: (builder) => ({
        authToken: builder.query({
            query: () => 'authToken',
            providesTags: ['Cpq'], //(result, error, token) => [{type: 'authToken'}]
        }),
    }),
});

const tagApi = createApi({
    reducerPath: 'tagApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/tags/' }),
    tagTypes: ['Tag', 'Project'],
    endpoints: (builder) => ({
        createTagItem: builder.mutation({
            query: ({ id, tagId, qty, config }) => ({
                url: `${tagId}/item`,
                method: 'POST',
                body: { quantity: qty, configuratorJson: config },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Tag', id: arg.tagId },
                { type: 'Project', id: arg.id },
            ],
        }),
        updateTagItem: builder.mutation({
            query: ({ tagId, qty, config, itemId }) => ({
                url: `${tagId}/item/${itemId}`,
                method: 'PUT',
                body: { quantity: qty, configuratorJson: config },
            }),
            invalidatesItems: (result, error, arg) => [
                { type: 'Item', id: arg.itemId },
            ],
        }),
    }),
});

const mainInitialState = {
    currentUser: null,
    hasAuthenticated: false,
    alerts: [],
    config: null,
    authToken: null,
    selectedItemId: null,
    // cpqData: {
    //     showModal: false,
    // },
    waitingModal: {
        show: false,
        msg: null,
    },
};

const newAlert = (kind, msg) => ({ kind, msg, id: uuidv4() });

const addAlertReducer = (kind, msg) => (state) => {
    state.alerts = [...state.alerts, newAlert(kind, msg)];
};

const mainSlice = createSlice({
    name: 'main',
    initialState: mainInitialState,
    reducers: {
        clearAlerts: (state) => {
            state.alerts = [];
        },
        clearAlert: (state, action) => {
            state.alerts = state.alerts.filter((x) => x.id !== action.payload);
        },
        addAlert: (state, action) => {
            const { kind, msg } = action.payload;
            state.alerts = [...state.alerts, newAlert(kind, msg)];
        },
        setSelectedItemId: (state, action) => {
            state.selectedItemId = action.payload;
        },
        // setCpqData: (state, action) => {
        //     state.cpqData = { ...state.cpqData, ...action.payload };
        // },
        showWaitingModal: (state, action) => {
            state.waitingModal = { show: true, msg: action.payload };
        },
        hideWaitingModal: (state) => {
            state.waitingModal = { show: false, msg: null };
        },
    },
    selectors: {
        getSelectedItemId: (state) => state.selectedItemId,
        // getCpqData: (state) => state.cpqData,
        getWaitingModalData: (state) => state.waitingModal,
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            projectsApi.endpoints.createProject.matchRejected,
            addAlertReducer('error', 'Could not create project'),
        );

        builder.addMatcher(
            projectsApi.endpoints.createProject.matchFulfilled,
            addAlertReducer('success', 'New project created'),
        );

        builder.addMatcher(
            projectsApi.endpoints.updateProject.matchRejected,
            addAlertReducer('error', 'Could not update project'),
        );

        builder.addMatcher(
            projectsApi.endpoints.updateProject.matchFulfilled,
            addAlertReducer('success', 'Project updated'),
        );

        builder.addMatcher(
            projectsApi.endpoints.deleteProject.matchFulfilled,
            addAlertReducer('success', 'Project deleted'),
        );

        builder.addMatcher(
            mainApi.endpoints.updateMySettings.matchRejected,
            addAlertReducer('error', 'Could not update user settings'),
        );

        builder.addMatcher(
            mainApi.endpoints.updateMySettings.matchFulfilled,
            addAlertReducer('success', 'User settings updated'),
        );

        builder.addMatcher(
            mainApi.endpoints.updateUser.matchRejected,
            addAlertReducer('error', 'Could not update user info'),
        );

        builder.addMatcher(
            mainApi.endpoints.updateUser.matchFulfilled,
            addAlertReducer('success', 'User info updated'),
        );

        builder.addMatcher(
            projectsApi.endpoints.createProjectTagItem.matchRejected,
            addAlertReducer('error', 'Could not add new product item'),
        );

        builder.addMatcher(
            projectsApi.endpoints.createProjectTagItem.matchFulfilled,
            addAlertReducer('success', 'Project updated'),
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'New product item added'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.createProjectTagTakeoff.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'New product items added'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.createProjectTagTakeoff.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not add new product items'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.updateProjectTagItem.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not update product item'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.updateProjectTagItem.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Product item updated'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.updateProjectTagItemQty.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not update product item quantity'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.updateProjectTagItemQty.matchFulfilled,
            (statue, action) => {
                statue.alerts = [
                    ...statue.alerts,
                    newAlert('success', 'Product item quantity updated'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.deleteProjectTagItem.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not delete product item'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.deleteProjectTagItem.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Product item deleted'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.deleteProjectTag.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not delete tag'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.deleteProjectTag.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Tag deleted'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.duplicateProjectTag.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not duplicate tag'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.duplicateProjectTag.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Tag duplicated'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.copyProjectTagItem.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not copy product item'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.copyProjectTagItem.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Product item copied'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.buildItemDocument.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not build item document'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.buildItemDocument.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Item document built'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.buildQuoteDocument.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not build quote document'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.buildQuoteDocument.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Quote document built'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.saveValidation.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not save validation result'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.saveValidation.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Validation result saved'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.uploadDrawings.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Drawing uploaded'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.uploadDrawings.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not upload drawing'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.deleteDrawing.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Drawing deleted'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.deleteDrawing.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not delete drawing'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.updateDrawing.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Drawing updated'),
                ];
            },
        );

        builder.addMatcher(
            projectsApi.endpoints.updateDrawing.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not update drawing'),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.getQuoteDocuments.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not get quote documents'),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.getQuoteDocuments.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Quote documents retrieved'),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.getProductDocuments.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Could not get product documents'),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.getProductDocuments.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Product documents retrieved'),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.submitProductDocJob.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert(
                        'error',
                        'Could not submit job of generating product document',
                    ),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.submitProductDocJob.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert(
                        'success',
                        'Job of generating product document submitted',
                    ),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.submitQuoteDocJob.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert(
                        'error',
                        'Could not submit job of generating quote document',
                    ),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.submitQuoteDocJob.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert(
                        'success',
                        'Job of generating quote document submitted',
                    ),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.deleteProductDocument.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Error on deleting product document'),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.deleteProductDocument.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Product docment deleted'),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.deleteQuoteDocument.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Error on deleting quote document'),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.deleteQuoteDocument.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Quote document deleted'),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.getProductHasJobInProgress.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Error in get item print job status'),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.getProductHasJobInProgress.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Item print job status retrieved'),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.getQuoteHasJobInProgress.matchRejected,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('error', 'Error in get quote print job status'),
                ];
            },
        );

        builder.addMatcher(
            documentsApi.endpoints.getQuoteHasJobInProgress.matchFulfilled,
            (state, action) => {
                state.alerts = [
                    ...state.alerts,
                    newAlert('success', 'Quote print job status retrieved'),
                ];
            },
        );
    },
});

const store = configureStore({
    reducer: {
        main: mainSlice.reducer,
        [mainApi.reducerPath]: mainApi.reducer,
        [projectsApi.reducerPath]: projectsApi.reducer,
        [categoryApi.reducerPath]: categoryApi.reducer,
        [cpqApi.reducerPath]: cpqApi.reducer,
        [tagApi.reducerPath]: tagApi.reducer,
        [documentsApi.reducerPath]: documentsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            mainApi.middleware,
            projectsApi.middleware,
            categoryApi.middleware,
            cpqApi.middleware,
            tagApi.middleware,
            documentsApi.middleware,
        ),
});

setupListeners(store.dispatch);

export const { actions: mainActions, selectors: mainSelectors } = mainSlice;

export const {
    useGetMeQuery,
    useGetMyProjectsQuery,
    useGetConfigQuery,
    useUpdateMySettingsMutation,
    useGetUsersQuery,
    useGetRolesQuery,
    useUpdateUserMutation,
} = mainApi;

export const {
    useGetProjectQuery,
    useGetProjectsQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useCreateProjectTagsMutation,
    useUpdateProjectTagMutation,
    useDeleteProjectTagMutation,
    useDuplicateProjectTagMutation,
    useCreateConfiguredItemMutation,
    useBuildItemDocumentMutation,
    useBuildQuoteDocumentMutation,
    useSaveValidationMutation,
    useUploadDrawingsMutation,
    useGetDrawingsQuery,
    useDeleteDrawingMutation,
    useUpdateDrawingMutation,
    useCreateOverlayMarkerMutation,
    useDeleteOverlayMarkerMutation,
    useDeleteProjectMutation,
    useCopyProjectMutation,
} = projectsApi;

export const {
    useGetCategoriesQuery,
    useGetCategoryQuery,
    useGetCategoryProductsQuery,
    useGetProductsQuery,
    useGetProductsContainingStringQuery,
} = categoryApi;

export const {
    useCreateProjectTagItemMutation,
    useCreateProjectTagTakeoffMutation,
    useUpdateProjectTagItemMutation,
    useDeleteProjectTagItemMutation,
    useCopyProjectTagItemMutation,
    useUpdateProjectTagItemQtyMutation,
} = projectsApi;

export const {
    useGetQuoteDocumentsQuery,
    useGetProductDocumentsQuery,
    useSubmitProductDocJobMutation,
    useSubmitQuoteDocJobMutation,
    useDeleteProductDocumentMutation,
    useDeleteQuoteDocumentMutation,
    useGetProductHasJobInProgressQuery,
    useGetQuoteHasJobInProgressQuery,
} = documentsApi;

export const { useCreateTagItemMutation, useUpdateTagItemMutation } = tagApi;

export const { useAuthTokenQuery } = cpqApi;

export default store;
