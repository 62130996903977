const mapResponse = (response) =>
    response
        .json()
        .catch((e) => {
            throw {
                statusCode: response.status,
                headers: response.headers,
                error: null,
            };
        })
        .then((json) => {
            if (response.ok) {
                return json;
            }
            throw {
                statusCode: response.status,
                headers: response.headers,
                error: json,
            };
        });

const callApi = (request) => {
    const transformedRequest = {
        ...request,
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(request.url, transformedRequest).then(mapResponse);
};

export const getItemValidate = (project, item) => async () => {
    let response;

    try {
        response = await callApi({
            url: `/api/projects/${project.id}/tags/${item.tagId}/item/${item.id}/validate`,
            method: 'GET',
        });
    } catch (error) {
        return null;
    }
    return response;
};

const compareFieldsArray = (fieldsName, srcFields, resultFields) => {
    for (let index = 0; index < fieldsName.length; index++) {
        const src = srcFields.find((x) => x.name === fieldsName[index].name);
        const refTarget = resultFields.find(
            (x) => x.name === fieldsName[index].name,
        );
        if (!refTarget || !src) return false;
        const srcValueType = Object.keys(src).find(
            (key) => key !== 'name' && key !== 'id',
        );
        const refTargetValueType = Object.keys(refTarget).find(
            (key) => key !== 'name' && key !== 'id',
        );
        if (srcValueType !== refTargetValueType) return false;
        if (
            src[fieldsName[index].valueType] !==
            refTarget[fieldsName[index].valueType]
        )
            return false;
    }
    return true;
};

const noramilizeFieldArray = (fields) => {
    const fieldsName = [];
    fields.forEach((element) => {
        const keys = Object.keys(element);
        const valType = keys.find((key) => key !== 'name' && key !== 'id');
        fieldsName.push({
            name: element.name,
            valueType: valType,
            value: element[valType],
        });
    });

    return fieldsName;
};

export const sameAsValidatedResult = (src, validatedResult) => {
    const srcFields = src?.fields;
    const resultFields = validatedResult?.fields;

    if (srcFields.length !== resultFields.length) return false;

    const fieldsName = noramilizeFieldArray(srcFields);
    if (!compareFieldsArray(fieldsName, srcFields, resultFields)) return false;

    const srcConfigs = src?.configurators;
    const resultConfigs = validatedResult?.configurators;

    if (srcConfigs.length !== resultConfigs.length) return false;
    const srcConfigNames = srcConfigs.map((x) => x.name);
    const resultConfigNames = resultConfigs.map((x) => x.name);
    if (srcConfigNames.length !== resultConfigNames.length) return false;
    for (let index = 0; index < srcConfigNames.length; index++) {
        const srcConfig = srcConfigs.find(
            (x) => x.name === srcConfigNames[index],
        );
        const resultConfig = resultConfigs.find(
            (x) => x.name === resultConfigNames[index],
        );
        if (!srcConfig || !resultConfig) return false;
        const srcConfigFields = srcConfig?.fields;
        const resultConfigFields = resultConfig?.fields;

        if (srcConfigFields.length !== resultConfigFields.length) return false;

        const configFieldsName = noramilizeFieldArray(srcConfigFields);
        if (
            !compareFieldsArray(
                configFieldsName,
                srcConfigFields,
                resultConfigFields,
            )
        )
            return false;
    }

    return true;
};
