import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const useTitle = (title) => {
    React.useEffect(() => {
        const previousTitle = document.title;
        document.title = title;

        return () => {
            document.title = previousTitle;
        };
    }, [title]);

    return null;
};

export const useSidebarContent = (contentFn) => {
    const { setSidebarContent } = useOutletContext();

    React.useEffect(() => {
        setSidebarContent(contentFn());
        return () => {
            setSidebarContent(null);
        };
    }, [contentFn, setSidebarContent]);

    return null;
};

export const buildConfiguratorWrapper = (jsonString) => {
    const obj = jsonString ? JSON.parse(jsonString) : null;

    const getFieldValues = (name, valueAccessor) =>
        obj?.configuredProduct?.fields
            ?.filter((field) => field.name === name)
            ?.map((field) => field?.[valueAccessor]) ?? [];

    const getFieldValue = (name, valueAccessor) =>
        obj?.configuredProduct?.fields?.find((field) => field.name === name)?.[
            valueAccessor
        ];

    const getFieldStringValue = (name) => getFieldValue(name, 'stringValue');

    const getTakeoffProducts = () =>
        JSON.parse(getFieldValue('fTakeoff', 'stringValue') ?? '[]');

    return {
        obj,
        getFieldValues,
        getFieldValue,
        getFieldStringValue,
        getTakeoffProducts,
    };
};

export const buildStateContext = (initialStaticState) => {
    const SetterContext = React.createContext();
    const GetterContext = React.createContext();

    const StateContextWrapper = ({ initialState, children }) => {
        const [state, setState] = React.useState(
            initialState ?? initialStaticState,
        );

        return (
            <SetterContext.Provider value={setState}>
                <GetterContext.Provider value={state}>
                    {children}
                </GetterContext.Provider>
            </SetterContext.Provider>
        );
    };

    const useStateContext = () => {
        return [
            React.useContext(GetterContext),
            React.useContext(SetterContext),
        ];
    };

    const withStateContext =
        (WrappedComponent) =>
        ({ initialState, ...rest }) => (
            <StateContextWrapper initialState={initialState}>
                <WrappedComponent {...rest} />
            </StateContextWrapper>
        );

    return { StateContextWrapper, useStateContext, withStateContext };
};

export const cpqDataState = buildStateContext({ showModal: false });
