const Colors = require('@swegon-core/ui-components/dist/common/theme/colors');
const Layout = require('@swegon-core/ui-components/dist/common/theme/layout');
const ElevationRaw = require('@swegon-core/ui-components/dist/common/theme/elevation');

const Elevation = {
    ElevationShadow1: ElevationRaw.ElevationShadow1.replace(/;\s*$/, ''),
    ElevationShadow2: ElevationRaw.ElevationShadow2.replace(/;\s*$/, ''),
    ElevationShadow3: ElevationRaw.ElevationShadow3.replace(/;\s*$/, ''),
    InnerShadow: ElevationRaw.InnerShadow.replace(/;\s*$/, ''),
};

module.exports = {
    Colors,
    Layout,
    Elevation,
};
