import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as storeExt from '../store';

const { useGetProductsContainingStringQuery } = storeExt;

const Search = ({ onFilter, onChange, onReset, productsLoading }) => {
    const [searchValue, setSearchValue] = useState('');
    const [hasValue, setHasValue] = useState(false);
    const { data: products, isLoading } =
        useGetProductsContainingStringQuery(searchValue);
    const [showSuggestions, setShowSuggestions] = useState(true);

    const textReference = useRef();

    const handleSubmit = (value) => {
        if (!productsLoading && value.length > 0) {
            onChange({
                type: 'search',
                details: value,
                title: `Showing results for - "${value}"`,
                backTitle: false,
            });

            onFilter({
                search: value,
            });

            setHasValue(true);
        }
    };

    const resetValue = () => {
        setSearchValue('');
        setHasValue(false);
        onReset();
    };

    const handleSelectSuggestion = (suggestion) => {
        console.log(suggestion);
        handleSubmit(suggestion);
        setSearchValue('');
    };

    const handleClick = () => {
        handleSubmit(searchValue);
        setSearchValue('');
    };

    const handleChange = (searchValue) => {
        setSearchValue(searchValue);
    };

    const handleBlur = () => {
        setShowSuggestions(false);
    };
    const handleFocus = () => {
        console.log('Focused!');
        setShowSuggestions(true);
    };

    useEffect(() => {}, [products]);

    return (
        <div className="relative">
            <div className="flex space-x-2">
                <input
                    type="text"
                    ref={textReference}
                    value={searchValue}
                    onChange={(e) => handleChange(e.target.value)}
                    placeholder="Search products"
                    disabled={productsLoading}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className="swegon-ui-text-input-wrapper border rounded border-black-10 h-10"
                />

                <div className="input-border rounded border-black-10">
                    {hasValue ? (
                        <button
                            className="swegon-like-button outlined h-10"
                            onClick={resetValue}
                            onMouseDown={(e) => e.preventDefault()}
                            disabled={productsLoading}
                        >
                            <FontAwesomeIcon icon="fa-solid fa-x" />
                        </button>
                    ) : (
                        <button
                            className="swegon-like-button outlined"
                            onClick={handleClick}
                            onMouseDown={(e) => e.preventDefault()}
                            disabled={productsLoading}
                        >
                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                        </button>
                    )}
                </div>
            </div>
            {showSuggestions && !isLoading && products.length > 0 && (
                <div
                    ref={textReference}
                    className="suggestions-dropdown absolute z-50 top-full left-0 w-full max-h-96 overflow-y-auto bg-white border border-black-10"
                >
                    <div className="scrollable-list">
                        {products.map((suggestion) => (
                            <div
                                key={suggestion.sku}
                                onMouseDown={() =>
                                    handleSelectSuggestion(suggestion.name)
                                }
                                className="flex items-center justify-between p-1 hover:bg-green-secondary"
                            >
                                <div>{suggestion.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Search;
