import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Tabs } from '../components';
import * as storeExt from '../store';
import { useParams } from 'react-router-dom';
import { ChevronButton } from './ChevronButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodashGroupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import flattenDeep from 'lodash/flattenDeep';
import { Spinner } from '@swegon-core/ui-components';
import { useHubConnection } from '../hubConnection';
import * as util from '../utils';

const {
    useGetConfigQuery,
    useGetMeQuery,
    useGetProjectQuery,
    useGetProductDocumentsQuery,
    useGetProductHasJobInProgressQuery,
    mainSelectors,
} = storeExt;

export const ProductDetailTabs = (props) => {
    const selectedItemId = useSelector(mainSelectors.getSelectedItemId);

    const { projectId } = useParams();
    const { data: project } = useGetProjectQuery(projectId);

    const selectedItem = React.useMemo(() => {
        if (!selectedItemId) {
            return null;
        }
        const item = flattenDeep(project?.tags?.map((x) => x?.items)).find(
            (x) => x.id === selectedItemId,
        );
        return item ?? null;
    }, [selectedItemId, project]);

    const selectedItemConfig = React.useMemo(() => {
        if (!(selectedItem?.configuratorJson?.length > 0)) {
            return null;
        }
        return JSON.parse(selectedItem.configuratorJson);
    }, [selectedItem]);

    return (
        <div>
            <Tabs.Context defaultValue="properties">
                <div className="bg-gray-400">
                    <Tabs.List
                        tabClassNames="border-x border-t border-black-20 text-base text-center bg-black-5 text-black-75 border-b grow no-underline"
                        activeTabClassNames="bg-white text-green-dark border-b-0"
                    >
                        <Tabs.Tab
                            value="properties"
                            label="Properties"
                        />
                        <Tabs.Tab
                            value="visulization"
                            label="3D Visualization"
                        />
                        <Tabs.Tab
                            value="documents"
                            label="Documents"
                        />
                    </Tabs.List>
                </div>
                <div className="bg-white border-x border-b border-black-20 py-2">
                    <Tabs.Panel value="properties">
                        <div className="px-4 pt-1">
                            <ProductProperties
                                selectedItemId={selectedItemId}
                                selectedItem={selectedItem}
                                selectedItemConfig={selectedItemConfig}
                            />
                        </div>
                    </Tabs.Panel>
                    <Tabs.Panel value="visulization">
                        <div className="px-4 pt-1">
                            <ProductVisualization
                                selectedItemConfig={selectedItemConfig}
                                exactWidth="100%"
                                exactHeight="600px"
                            />
                        </div>
                    </Tabs.Panel>
                    <Tabs.Panel value="documents">
                        <div className="px-4 pt-1">
                            <ProductDocuments selectedItem={selectedItem} />
                        </div>
                    </Tabs.Panel>
                </div>
            </Tabs.Context>
        </div>
    );
};

const ProductProperties = (props) => {
    const { selectedItemId, selectedItem, selectedItemConfig } = props;

    // const selectedItemId = useSelector(mainSelectors.getSelectedItemId);

    // const { projectId } = useParams();
    // const { data: project } = useGetProjectQuery(projectId);

    // const selectedItem = React.useMemo(() => {
    //     if (!selectedItemId) {
    //         return null;
    //     }
    //     const item = flattenDeep(project?.tags?.map(x => x?.items)).find((x) => x.id === selectedItemId);
    //     return item ?? null;
    // }, [selectedItemId, project]);

    // const selectedItemConfig = React.useMemo(() => {
    //     if (!(selectedItem?.configuratorJson?.length > 0)) {
    //         return null;
    //     }
    //     return JSON.parse(selectedItem.configuratorJson);
    // }, [selectedItem]);

    const requiredProperties = React.useMemo(() => {
        if (!selectedItemConfig?.idQuote) {
            return null;
        }

        const propertiesObject =
            selectedItemConfig?.configuredProduct?.fields?.find(
                (x) => x.name === 'fProperties',
            );
        return propertiesObject
            ? orderBy(JSON.parse(propertiesObject.stringValue), [
                  'ordinal',
                  'asc',
              ])
            : null;
    }, [selectedItemConfig]);

    if (selectedItem?.product) {
        return (
            <div>
                <div className="text-2xl font-bold pb-2">Details</div>
                <img
                    src={selectedItem.product.image}
                    alt="Product Image"
                    className="mx-auto"
                />
                <div className="text-justify">
                    {selectedItem.product.description}
                </div>
                {selectedItem.product.documents.length > 0 && (
                    <div>
                        <div className="mt-4 text-bold">
                            Additional Resources:
                        </div>
                        {selectedItem.product.documents.map((document) => (
                            <div
                                className="mt-2 flex items-center"
                                key={document.url}
                            >
                                <FontAwesomeIcon
                                    icon="fa-regular fa-file"
                                    className="mr-2"
                                />
                                <div className="mt-1 text-green-dark hover:text-amber-100">
                                    <a
                                        href={document.url}
                                        target="_blank"
                                    >
                                        {document.name}
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    return requiredProperties ? (
        <div className="border border-black-20 px-4 py-4 divide-y divide-black-20">
            <div className="text-2xl font-bold pb-2">Properties</div>
            <div className="pt-2">
                {requiredProperties.map((property) => (
                    <ProductProperty
                        key={selectedItemId + '-' + property.name}
                        property={property}
                        parentKey={selectedItemId + '-' + property.name}
                    />
                ))}
            </div>
        </div>
    ) : (
        <div className="text-green-dark">
            {!selectedItemId
                ? 'Select a product to display its properties'
                : 'Cannot find required properties for selected product'}
        </div>
    );
};

const ProductProperty = (props) => {
    const { property, parentKey } = props;

    const [open, setOpen] = React.useState(true);

    return (
        <div
            className="text-lg font-bold py-2"
            onClick={() => setOpen(!open)}
        >
            <div className="grid grid-cols-2 content-stretch">
                <div>{property.name}</div>
                <div className="justify-self-end">
                    <ChevronButton open={open} />
                </div>
            </div>
            {open ? (
                <section>
                    {orderBy(property.attributes, ['ordinal', 'asc']).map(
                        (attribute, index) => (
                            <PropertyDetail
                                key={parentKey + '-' + attribute.name}
                                attribute={attribute}
                                index={index}
                            />
                        ),
                    )}
                </section>
            ) : null}
        </div>
    );
};

const PropertyDetail = (props) => {
    const { attribute, index } = props;

    return (
        <div
            className={classNames(
                'flex justify-between text-base font-normal font-extra-light text-gray-30 pl-4 pr-6 py-2',
                index % 2 === 0 ? 'bg-gray-5' : '',
            )}
        >
            <div>{attribute.name}</div>
            <div className="justify-self-end">{attribute.value}</div>
        </div>
    );
};

const ProductVisualization = (props) => {
    const { selectedItemConfig, exactWidth, exactHeight } = props;

    // const selectedItemId = useSelector(mainSelectors.getSelectedItemId);

    const { data: config } = useGetConfigQuery();
    const { data: currentUser } = useGetMeQuery();
    const viewerElementReference = React.useRef(null);
    const [embedConfigurator, setEmbedConfigurator] = React.useState(null);

    const currency =
        currentUser?.settings?.find((s) => s.key === 'currency')?.value ??
        'USD';

    const kbmaxUrl = config?.kbmaxUrl;

    // const isWidthNotValid = React.useMemo(() => {
    //     return width === null || width === undefined;
    // }, [width]);

    // const calcWidth = React.useMemo(() => {
    //     return isWidthNotValid ? 0 : Math.max(Math.floor(width - 30), 847);
    // }, [isWidthNotValid, width]);

    // const widthTooSmall = React.useMemo(() => {
    //     return calcWidth < 847;
    // }, [calcWidth]);

    // const widthValue = React.useMemo(() => {
    //     return isWidthNotValid ? '850px' : Math.floor(width - 30) + 'px';
    // }, [isWidthNotValid, width]);

    // const heightValue = React.useMemo(() => {
    //     return isWidthNotValid
    //         ? '450px'
    //         : Math.floor((width - 30) * 0.6) + 'px';
    // }, [isWidthNotValid, width]);

    const isTakeOffProd = React.useMemo(() => {
        return selectedItemConfig?.configuredProduct?.name === 'Takeoff';
    }, [selectedItemConfig]);

    React.useEffect(() => {
        if (!kbmaxUrl || !selectedItemConfig) return;

        const addScript = document.createElement('script');
        addScript.setAttribute('src', `${kbmaxUrl}/embed.min.js`);
        document.body.append(addScript);

        return () => {
            return addScript.remove();
        };
    }, [kbmaxUrl, selectedItemConfig]);

    React.useEffect(() => {
        if (
            !viewerElementReference.current ||
            !kbmaxUrl ||
            !selectedItemConfig?.idProduct ||
            !currency ||
            isTakeOffProd
        ) {
            return;
        }

        const configurator = new window.kbmax.ConfiguratorEmbed({
            kbmaxUrl,
            elementId: 'visualizationViewer',
            configuratorId: selectedItemConfig.idProduct,
            showHeader: false,
            showDrawer: false,
            showSubmitButton: false,
            showMove: false,
            showFields: false,
            loadStyle: 'none',
            idTheme: 15,
            currency,
        });

        setEmbedConfigurator(configurator);
    }, [
        viewerElementReference.current,
        selectedItemConfig,
        currency,
        kbmaxUrl,
        isTakeOffProd,
    ]);

    React.useEffect(() => {
        if (
            embedConfigurator &&
            selectedItemConfig?.configuredProduct &&
            !isTakeOffProd
        ) {
            embedConfigurator.setConfiguredProduct(
                selectedItemConfig.configuredProduct,
            );
        }
    }, [embedConfigurator, selectedItemConfig]);

    // if (!exactWidth && !exactHeight && isSelected && widthTooSmall) {
    //     return (
    //         <div className="text-green-dark">
    //             Area for rendering 3D visualization is too small. Please enlarge
    //             you browser window or use a higher resolution.
    //         </div>
    //     );
    // }

    if (!selectedItemConfig) {
        return (
            <div className="text-green-dark">
                Select a product to display its 3D visualization
            </div>
        );
    }
    // if (preConfigProdcut) {
    //     return (
    //         <div className="text-green-dark">
    //             3D visualization for pre-configured products not implemented yet
    //         </div>
    //     );
    // }
    if (isTakeOffProd) {
        return (
            <div className="text-green-dark">
                3D visualization for take-off products not implemented yet
            </div>
        );
    }
    return (
        <div className="bg-white px-1 py-1 sm:p-1 sm:pb-4">
            <div
                style={{
                    height: exactHeight,
                    width: exactWidth,
                }}
                className="mb-1"
                ref={viewerElementReference}
                id="visualizationViewer"
            />
        </div>
    );
};

const ProductDocuments = (props) => {
    const { selectedItem } = props;
    const dispatch = useDispatch();
    const { data: config } = useGetConfigQuery();
    const hubConnection = useHubConnection();
    const [cpqData, setCpqData] = util.cpqDataState.useStateContext();

    const { data: stillprinting } = useGetProductHasJobInProgressQuery(selectedItem?.id ?? '');
    const {
        data: documents,
        error,
        isLoading: isDocLoading,
    } = useGetProductDocumentsQuery(selectedItem?.id ?? '');

    React.useEffect(() => {
        if (!cpqData.itemNeedRefresh) return;
        dispatch(storeExt.documentsApi.util.prefetch('getProductHasJobInProgress',
            cpqData.ItemNeedRefresh ?? '', { force: true, }));
        setCpqData({ showModal : false, });
    }, [cpqData, dispatch, setCpqData])

    React.useEffect(() => {
        const handleReceiveItemDocMessage = (jobId, itemId) => {
            dispatch(storeExt.documentsApi.util.prefetch('getProductDocuments',
                itemId, { force: true, }));
            dispatch(storeExt.documentsApi.util.prefetch('getProductHasJobInProgress',
                itemId, { force: true, }));
        }
        const sendItemDocAck = (connection, jobId, itemId) => {
            connection.invoke('ItemDocAcknowledge', jobId, itemId);
        };

        if (hubConnection && config?.singalR) {
            hubConnection.then((connection) => {
                connection.on(
                    config.singalR.ItemDoc ?? 'ItemMessage',
                    (jobId, itemId) => {
                        handleReceiveItemDocMessage(jobId, itemId);
                        sendItemDocAck(connection, jobId, itemId)
                    },
                );
            });
        }

        return () => {
            if (hubConnection && config?.singalR) {
                hubConnection.then((connection) => {
                    connection.off(
                        config.singalR.ItemDoc ?? 'ItemMessage',
                        handleReceiveItemDocMessage,
                    );
                });
            }
        };
    }, [hubConnection, config, dispatch]);

    return (
        <div className="border border-black-20 px-4 py-4 divide-y divide-black-20">
            <div className="text-2xl font-bold pb-2">Documents</div>
            <table className="tags-table">
                <ProductDocumentHeader />
                {selectedItem && !isDocLoading && documents?.length > 0 ? (
                    <ProductDocumentBody
                        selectedItem={selectedItem}
                        documents={documents}
                        hasJobInProgrss={stillprinting}
                    />
                ) : selectedItem && isDocLoading ? (
                    <tbody>
                        <tr>
                            <td className="pl-1 pr-1">&nbsp;</td>
                            <td
                                colSpan="3"
                                className="text-center"
                            >
                                Loading documents...
                            </td>
                        </tr>
                        <tr>
                            <td className="pl-1 pr-1">&nbsp;</td>
                            <td
                                colSpan="3"
                                className="text-center"
                            >
                                <Spinner color="green" />
                            </td>
                        </tr>
                    </tbody>
                ) : null}
            </table>
            <div className="pt-2 text-green-dark">
                {selectedItem
                    ? documents?.length === 0
                        ? 'No documents found for selected product'
                        : null
                    : 'Select a product to display its documents'}
            </div>
        </div>
    );
};

const ProductDocumentHeader = () => {
    return (
        <thead className="text-black-30">
            <tr>
                <th className="pl-1 pr-1 w-1">{'\xa0'}</th>
                <th className="pl-1 pr-4">Filename</th>
                <th className="px-4">Type</th>
                <th className="px-4">Date Created</th>
                <th className="p-1 pr-1"></th>
            </tr>
        </thead>
    );
};

const DocRow = (props) => {
    const { selectedItem, document, firstRow, headerRow, status, onClick, projectId } =
        props;
    const [deleteProdDoc, deleteProdDocResult] =
        storeExt.useDeleteProductDocumentMutation();
    const firstCol =
        firstRow && headerRow ? (
            <ChevronButton
                open={status}
                onClick={() => onClick(!status)}
            />
        ) : !firstRow ? (
            <FontAwesomeIcon
                icon="fa-solid fa-triangle-exclamation"
                style={{ color: '#d21414' }}
            />
        ) : null;
        
    const handleDeleteDoc = async (item, doc) => {
        try {
            await deleteProdDoc({
                id: projectId,
                tagId: item.tagId,
                itemId: doc.itemId,
                docId: doc.id,
                seqId: doc.sequenceId,
            }).unwrap();
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <tr
            className="border-b border-t border-black-20 text-base"
            key={document.id}
        >
            <td className="pl-1 pr-1">{firstCol}</td>
            <td className="pl-1 pr-4">
                <a
                    className="text-green-primary hover:underline hover:cursor-pointer"
                    href={`/api/documents/item/${selectedItem.id}/doc/${document.docId}`}
                    download={document.originalDocName}
                >
                    {document.docName}
                </a>
            </td>
            <td className="px-4">&nbsp;</td>
            <td className="px-4">
                {new Date(document.docCreatedAt).toLocaleString('en-US')}
            </td>
            <td>
                <button
                    onClick={() => handleDeleteDoc(selectedItem, document)}
                >
                    <FontAwesomeIcon icon="fa-solid fa-trash-can" />
                </button>
            </td>
        </tr>
    );
};

const DocRows = (props) => {
    const { selectedItem, documents, projectId } = props;

    const [open, setOpen] = React.useState(false);

    if (documents.length === 1) {
        return (
            <DocRow
            key={documents[0].id}
                selectedItem={selectedItem}
                document={documents[0]}
                firstRow={true}
                headerRow={false}
                projectId={projectId}
            />
        );
    }

    const orderedDocs = orderBy(documents, ['docCreatedAt'], ['desc']);

    return (
        <React.Fragment>
            <DocRow
                key={orderedDocs[0].id}
                selectedItem={selectedItem}
                document={orderedDocs[0]}
                firstRow={true}
                headerRow={true}
                status={open}
                onClick={setOpen}
                projectId={projectId}
            />
            {open
                ? orderedDocs.map((doc, index) => {
                      if (index !== 0) {
                          return (
                              <DocRow
                                  key={doc.id}
                                  selectedItem={selectedItem}
                                  document={doc}
                                  firstRow={false}
                                  headerRow={false}
                                  projectId={projectId}
                              />
                          );
                      }
                      return null;
                  })
                : null}
        </React.Fragment>
    );
};

const ProductDocumentBody = (props) => {
    const { selectedItem, documents, hasJobInProgrss } = props;
    const { projectId } = useParams();
    const cleanedDocs = documents.map((document) => {
        return {
            docName: document.docName.trim(),
            originalDocName: document.originalDocName,
            docId: document.docId,
            revision: document.revision,
            docCreatedAt: document.docCreatedAt,
            itemId: document.itemId,
            item: document.item,
            createdAt: document.createdAt,
            id: document.id,
            sequenceId: document.sequenceId,
        };
    });

    const groups = lodashGroupBy(cleanedDocs ?? [], 'docName');

    return (
        <tbody>
            { hasJobInProgrss
                ? (
                <tr
                    className="border-t border-black-20 text-base"
                    key={'Spinning'}
                >
                    <td align="center" colSpan="5">
                        <Spinner color='green' />
                    </td>
                </tr>)
                : null
            }
            {Object.entries(groups).map(([name, docs]) => (
                <DocRows
                    key={name}
                    selectedItem={selectedItem}
                    documents={docs}
                    projectId={projectId}
                />
            ))}
        </tbody>
    );
};
