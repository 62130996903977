import React, { useEffect, useMemo, useState } from 'react';
import * as storeExt from '../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const {
    useGetCategoryProductsQuery,
    useGetProductsQuery,
    useCreateConfiguredItemMutation,
} = storeExt;

const Results = ({ previousZone, tag, projectId }) => {
    const queryHook =
        previousZone.type === 'category'
            ? useGetCategoryProductsQuery
            : useGetProductsQuery;
    const { data: products } = queryHook(previousZone.details);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [createConfiguredItem] = useCreateConfiguredItemMutation();

    const handleAddToCart = (productId) => {
        createConfiguredItem({ id: projectId, tag, productId });
    };

    let displayedProducts = null;
    let totalPages = 0;

    if (products) {
        totalPages = Math.ceil(products.length / itemsPerPage);
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        const productsToDisplay = products.slice(startIndex, endIndex);

        displayedProducts = (
            <div className="grid grid-cols-2">
                {productsToDisplay.map((product) => (
                    <div className="border-2 border-black-5 p-2 -m-[1px]">
                        <div
                            key={product.id}
                            className="flex items-center"
                        >
                            <h2 className="text-sm text-black flex-grow">
                                {product.name}
                            </h2>
                            <button
                                className="swegon-like-button contained text-sm"
                                onClick={() => handleAddToCart(product.id)}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div>
            {displayedProducts}
            {totalPages > 1 && (
                <div className="flex justify-between">
                    <button
                        className="swegon-like-button outlined "
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                    </button>
                    <span>{`Page ${currentPage} of ${totalPages}`}</span>
                    <button
                        className="swegon-like-button outlined"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default Results;
