import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as storeExt from '../store';
import * as utils from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { useGetConfigQuery, useGetProjectQuery, mainActions } = storeExt;

const DisplayProjectSiteInfo = (props) => {
    const { project } = props;
    const hasSiteInfo = !(project?.siteInfo === null || project?.siteInfo === undefined);
    const hasSiteInfoCity = hasSiteInfo && !(project.siteInfo.city === null || project.siteInfo.city === undefined);
    const hasSiteInfoJson = project?.siteInfoConfiguratorJson;
    const hasOldSiteInfo = (hasSiteInfo && !hasSiteInfoCity) && hasSiteInfoJson;

    const oldSiteInfo = React.useMemo(() => {
        if (hasOldSiteInfo) {
            const jsonObj = JSON.parse(project.siteInfoConfiguratorJson);
            const siteInfoStr = jsonObj.fields?.find(
                (x) => x.name === 'fVM',
            )?.stringValue;
            const siteInfo = siteInfoStr ? JSON.parse(siteInfoStr) : undefined;
            return siteInfo;
        }
        return null;
    }, [project, hasOldSiteInfo]);

    const renderOldSiteInfo = (oldSiteInfo) => {
        return (
            <div className="text-sm">
                <div>
                    {`${oldSiteInfo.location.city}, ${
                        oldSiteInfo.location.state
                    } ${oldSiteInfo.location.zipCode ?? ''}`}
                </div>
                <div>
                    {oldSiteInfo.seismicLoadCalculationParameters
                        .siteClassificationLetter
                        ? `Class ${oldSiteInfo.seismicLoadCalculationParameters.siteClassificationLetter}`
                        : ''}
                </div>
            </div>
        );
    }

    const renderNewSiteInfo = (siteInfo) => {
        if (siteInfo) {
            return (
                <div className="text-sm">
                    <div>
                        {`${project.siteInfo.city} ${
                            project.siteInfo.state
                        } `}
                    </div>
                    <div>
                        {project.siteInfo.siteClass
                            ? `Class ${project.siteInfo.siteClass}`
                            : ''}
                    </div>
                </div>
            );
        } else {
            return (<div>Add Site Conditions</div>);
        }
    }

    return (
        <React.Fragment>
            { hasOldSiteInfo && renderOldSiteInfo(oldSiteInfo) }
            { !hasOldSiteInfo && renderNewSiteInfo(project?.siteInfo)}
        </React.Fragment>
    );
};

export const ProjectHeader = (props) => {
    const {
        parentPath,
        isUpdatingProjectName,
        updatedProjectName,
        setIsUpdatingProjectName,
        setUpdatedProjectName,
        handleNameUpdate,
        onSiteInfoConfiguratorLoaded,
        handleSiteInfoUpdate,
        setExportState,
    } = props;
    const [, setCpqData] = utils.cpqDataState.useStateContext();
    const { projectId } = useParams();
    const { data: config } = useGetConfigQuery();
    const { data: project } = useGetProjectQuery(projectId);

    return (
        <div className="flex py-4 text-lg items-center">
            <div className="flex items-center border-r border-green-dark pr-4 mr-4">
                <div className="mr-2">
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon
                            icon="fa-solid fa-star"
                            size="2xs"
                        />
                        <FontAwesomeIcon
                            icon="fa-regular fa-folder"
                            size="xl"
                        />
                    </span>
                </div>
                <div>
                    {parentPath === 'projects' ? (
                        isUpdatingProjectName ? (
                            <input
                                type="text"
                                onBlur={handleNameUpdate}
                                onChange={(e) =>
                                    setUpdatedProjectName(e.target.value)
                                }
                                value={updatedProjectName}
                            />
                        ) : (
                            <div
                                className="text-green-dark"
                                onClick={() => setIsUpdatingProjectName(true)}
                            >
                                {project?.name ?? 'Project'}
                            </div>
                        )
                    ) : (
                        <div className="text-green-dark">
                            {project?.name ?? 'Project'}
                        </div>
                    )}
                </div>
            </div>
            <div className="flex items-center">
                <div className="mr-2">
                    <FontAwesomeIcon
                        icon="fa-solid fa-map-location-dot"
                        size="lg"
                    />
                </div>
                {parentPath === 'projects' ? (
                    <div
                        className="underline cursor-pointer text-green-dark"
                        onClick={() => {
                            setCpqData({
                                showModal: true,
                                configuratorId:
                                    config?.configurators?.SeismicAndWind,
                                onConfiguratorLoaded:
                                    onSiteInfoConfiguratorLoaded,
                                onSubmit: handleSiteInfoUpdate,
                            });
                        }}
                    >
                        <DisplayProjectSiteInfo project={project} />
                    </div>
                ) : (
                    <div className="underline text-green-dark">
                        <DisplayProjectSiteInfo project={project} />
                    </div>
                )}
            </div>
            {parentPath === 'documents' ? (
                <React.Fragment>
                    <div className="flex-grow ml-4">&nbsp;</div>
                    <div
                        className="flex ml-4 mr-4 w-28 h-10 border-2 border-green-primary text-base font-bold text-green-primary justify-center items-center hover:cursor-pointer"
                        onClick={() => setExportState(true)}
                    >
                        EXPORT
                    </div>
                    {/*
                    <button className="ml-4 mr-8 w-28 h-10 border-2 border-green-primary text-base font-bold text-green-primary">
                        EXPORT
                    </button>
                    */}
                </React.Fragment>
            ) : null}
        </div>
    );
};
