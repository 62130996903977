import React from 'react';
import { useParams } from 'react-router-dom';
import * as storeExt from '../store';
import { useTitle } from '../utils';
import { ProjectHeader } from '../components/ProjectHeader';
import { ExportDocModal, WaitingModal } from '../modals';
import lodashGroupBy from 'lodash/groupBy';
import { ChevronButton } from '../components/ChevronButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@swegon-core/ui-components';
import { useHubConnection } from '../hubConnection';
import { useDispatch } from 'react-redux';

const {
    useGetProjectQuery,
    useBuildQuoteDocumentMutation,
    useGetConfigQuery,
    useGetQuoteDocumentsQuery,
    useSubmitQuoteDocJobMutation,
    useDeleteQuoteDocumentMutation,
    documentsApi,
    mainActions,
} = storeExt;

const SortByDocCreatedDateAscending = (a, b) =>
    SortByDocCreatedDate(a, b, true);

const SortByDocCreatedDateDescending = (a, b) =>
    SortByDocCreatedDate(a, b, false);

const SortByDocCreatedDate = (a, b, ascending = true) => {
    if (a.createdAt < b.createdAt) {
        return ascending ? -1 : 1;
    }
    if (a.createdAt > b.createdAt) {
        return ascending ? 1 : -1;
    }
    return 0;
};

export const Document = () => {
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const { data: project } = useGetProjectQuery(projectId);
    const {
        data: documents,
        error,
        isLoading: isDocLoading,
    } = useGetQuoteDocumentsQuery(projectId);
    const { data: config } = useGetConfigQuery();
    const { data: stillprinting } = storeExt.useGetQuoteHasJobInProgressQuery(projectId);
    const [buildDocument, buildDocumentResult] =
        useBuildQuoteDocumentMutation();
    const [submitBuildJob, submitBuildJobResult] =
        useSubmitQuoteDocJobMutation();
    const [showExportModal, setExportModalOpen] = React.useState(false);
    const [selectedSubmittal, setSelectedSubmittal] = React.useState(true);
    const [selectedDataSheets, setSelectedDataSheets] = React.useState(false);
    const [selectedCalcSummary, setSelectedCalcSummary] = React.useState(false);
    const [selectedCADDrawings, setSelectedCADDrawings] = React.useState(false);
    // const [showWaitingModal, setShowWaitingModal] = React.useState(false);
    // const [waitingModalMsg, setWaitingModalMsg] = React.useState({
    //     isSimple: true,
    //     msg: null,
    // });
    const needExport = React.useMemo(() => {
        return (
            selectedSubmittal ||
            selectedDataSheets ||
            selectedCalcSummary ||
            selectedCADDrawings
        );
    }, [
        selectedSubmittal,
        selectedDataSheets,
        selectedCalcSummary,
        selectedCADDrawings,
    ]);
    const quoteDocuments = React.useMemo(
        () => config?.quoteDocuments ?? {},
        [config],
    );

    useTitle(React.useMemo(() => project?.name ?? 'Project', [project]));

    const hubConnection = useHubConnection();
    React.useEffect(() => {
        const handleReceiveItemDocMessage = (jobId, itemId) => {
            dispatch(
                documentsApi.util.prefetch('getProductDocuments', itemId,
                    { force: true, }),
            );
            dispatch(
                documentsApi.util.prefetch('getProductHasJobInProgress',
                    itemId, { force: true, }),
            );
        };
        const sendItemDocAck = (connection, jobId, itemId) => {
            connection.invoke('ItemDocAcknowledge', jobId, itemId);
        };
        const handleReceiveQuoteDocMessage = (jobId, quoteId) => {
            // console.log("In document, received quote job done message....");
            dispatch(
                documentsApi.util.prefetch('getQuoteDocuments', quoteId,
                    { force: true, }),
            );
            dispatch(
                documentsApi.util.prefetch('getQuoteHasJobInProgress', quoteId,
                    { force: true, }),
            );
        };
        const sendQuoteDocAck = (connection, jobId, quoteId) => {
            connection.invoke('QuoteDocAcknowledge', jobId, quoteId);
        };

        if (hubConnection && config?.signalR) {
            hubConnection.then((connection) => {
                // console.log("Subscribing in document....");
                connection.on(
                    config.signalR.ItemDoc ?? 'ItemMessage',
                    (jobId, itemId) => {
                        handleReceiveItemDocMessage(jobId, itemId);
                        sendItemDocAck(connection, jobId, itemId);
                    },
                );
                connection.on(
                    config.signalR.QuoteDoc ?? 'QuoteMessage',
                    (jobId, quoteId) => {
                        handleReceiveQuoteDocMessage(jobId, quoteId);
                        sendQuoteDocAck(connection, jobId, quoteId);
                    },
                );
            });
        }

        // Clean up
        return () => {
            if (hubConnection && config?.signalR) {
                hubConnection.then((connection) => {
                    // console.log('Unsubscribing in document.....');
                    connection.off(
                        config.signalR.ItemDoc ?? 'ItemMessage',
                        handleReceiveItemDocMessage,
                    );
                    connection.off(
                        config.signalR.QuoteDoc ?? 'QuoteMessage',
                        handleReceiveQuoteDocMessage,
                    );
                });
            }
        };
    }, [hubConnection, config, dispatch]);

    const exportMenu = [
        {
            label: 'Submittal',
            value: 'Submittal',
            checked: selectedSubmittal,
            description:
                'Export sales submittal information for each of the products and/or configurations.',
            onChange: setSelectedSubmittal,
            disabled: false,
        },
        {
            label: 'Data Sheets',
            value: 'DataSheets',
            checked: selectedDataSheets,
            description: 'Export data sheets for each product.',
            onChange: setSelectedDataSheets,
            disabled: true,
        },
        {
            label: 'Calculation Summary',
            value: 'CalculationSummary',
            checked: selectedCalcSummary,
            description:
                'Export calculation summaries where there is supporting documents.',
            onChange: setSelectedCalcSummary,
            disabled: true,
        },
        {
            label: 'CAD Details',
            value: 'CADDetails',
            checked: selectedCADDrawings,
            description: 'Export CAD details for configured products.',
            onChange: setSelectedCADDrawings,
            disabled: true,
        },
    ];

    const exportDocument = async () => {
        if (selectedSubmittal) {
            dispatch(
                mainActions.showWaitingModal({
                    isSimple: false,
                    msg: "Exporting Project's Sales Submittals...\nPlease wait as it will take a while.",
                }),
            );
            setExportModalOpen(false);
            try {
                /*
                await buildDocument({
                    id: project.id,
                    docType: quoteDocuments.SalesSubmittals,
                }).unwrap();
                */
                await submitBuildJob({
                    id: project.id,
                    docType: quoteDocuments.SalesSubmittals,
                }).unwrap();
            } catch (err) {
                console.log(err);
            } finally {
                dispatch(mainActions.hideWaitingModal());
                dispatch(documentsApi.util.prefetch('getQuoteHasJobInProgress',
                    projectId, { force: true, }));
            }
        }
        setExportModalOpen(false);
    };

    return (
        <div className="">
            <ProjectHeader
                parentPath="documents"
                setExportState={setExportModalOpen}
            />
            <DocumentContent
                project={project}
                isLoading={isDocLoading}
                documents={documents}
                hasJobInProgress={stillprinting}
            />
            <ExportDocModal
                show={showExportModal}
                menu={exportMenu}
                project={project}
                onClose={() => setExportModalOpen(false)}
                onExport={exportDocument}
                needExport={needExport}
            />
        </div>
    );
};

const DocumentContent = (props) => {
    const { project, isLoading, documents, hasJobInProgress } = props;
    const hasDocumetns = documents?.length > 0;

    return (
        <div className="bg-white border border-black-20 px-4 py-4">
            <div className="border border-black-20 px-4 py-4 divide-y divide-black-20">
                <div className="text-2xl font-bold mb-2">Documents</div>
                <table className="tags-table">
                    <DocumentHeader />
                    {isLoading ? (
                        <tbody>
                            <tr>
                                <td className="pl-1 pr-1">&nbsp;</td>
                                <td colSpan="4">Loading documents...</td>
                            </tr>
                            <tr>
                                <td className="pl-1 pr-1">&nbsp;</td>
                                <td colSpan="4">
                                    <Spinner color="green" />
                                </td>
                            </tr>
                        </tbody>
                    ) : hasDocumetns ? (
                        <DocumentBody
                            project={project}
                            documents={documents}
                            hasJobInProgress={hasJobInProgress}
                        />
                    ) : (
                        <tbody>
                            { hasJobInProgress
                                ? (
                                    <tr
                                        key={'Spinning'}
                                    >
                                        <td align="center" colSpan="4">
                                            <Spinner color='green' />
                                        </td>
                                    </tr>)
                                : null
                            }
                            <tr>
                                <td className="pl-1 pr-1">&nbsp;</td>
                                <td colSpan="4">
                                    Currently no documents to show here
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    );
};

const DocumentHeader = () => {
    return (
        <thead className="text-black-30 border-t-2 border-b-2 border-black-20">
            <tr>
                <th className="pl-1 pr-1 w-1">{'\xa0'}</th>
                <th className="pl-1 pr-4 w-3/5">FileName</th>
                <th className="px-4 w-1 w-1/5">Revison</th>
                <th className="pl-4 pr-1">Date Created</th>
                <th className="pl-1 pr-1 w-1">{'\xa0'}</th>
            </tr>
        </thead>
    );
};

const DocRow = (props) => {
    const { project, document, firstRow, headerRow, status, onClick } = props;
    const [deleteQuoteDoc, deleteQuoteDocResult] =
        useDeleteQuoteDocumentMutation();
    const firstCol =
        firstRow && headerRow ? (
            <ChevronButton
                open={status}
                onClick={() => onClick(!status)}
            />
        ) : !firstRow ? (
            <FontAwesomeIcon
                icon="file-solid fa-triangle-exclamation"
                style={{ color: '#d21414' }}
            />
        ) : null;

    if (!project) return null;

    const handleDeleteDoc = async (doc) => {
        try {
            await deleteQuoteDoc({
                id: project.id,
                docId: doc.id,
                seqId: doc.sequenceId,
            }).unwrap();
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <tr
            className="border-b border-t border-black-20 text-base"
            key={document.id}
        >
            <td className="pl-1 pr-1">{firstCol}</td>
            <td className="pl-1 pr-4">
                <a
                    className="text-green-primary hover:underline hover:curosr-pointer"
                    href={`/api/documents/quote/${project.id}/doc/${document.docId}`}
                    download={document.originalDocName}
                >
                    {document.originalDocName}
                </a>
            </td>
            <td className="px-4">{document.revision}</td>
            <td className="px-4">
                {new Date(document.createdAt).toLocaleString('en-US')}
            </td>
            <td>
                <button
                    onClick={() => handleDeleteDoc(document)}
                >
                    <FontAwesomeIcon
                        className="mr-6"
                        icon="fa-solid fa-trash-can"
                    />
                </button>
            </td>
        </tr>
    );
};

const DocRows = (props) => {
    const { project, docs } = props;

    const [open, setOpen] = React.useState(false);

    if (docs.length === 1) {
        return (
            <DocRow
                document={docs[0]}
                project={project}
                firstRow={true}
                headerRow={false}
            />
        );
    }

    const orderedDocs = docs.sort((a, b) =>
        SortByDocCreatedDateDescending(a, b),
    );

    return (
        <React.Fragment>
            <DocRow
                key={orderedDocs[0].id}
                project={project}
                document={orderedDocs[0]}
                firstRow={true}
                headerRow={true}
                status={open}
                onClick={setOpen}
            />

            {open
                ? orderedDocs.map((doc, index) => {
                      if (index !== 0) {
                          return (
                              <DocRow
                                  key={doc.id}
                                  project={project}
                                  document={doc}
                                  firstRow={false}
                                  headerRow={false}
                              />
                          );
                      }
                      return null;
                  })
                : null}
        </React.Fragment>
    );
};

const DocumentBody = (props) => {
    const { project, documents, hasJobInProgress } = props;

    const groups = lodashGroupBy(documents ?? [], 'originalDocName');

    return (
        <tbody>
            { hasJobInProgress
                ? (
                    <tr
                        key={'Spinning'}
                    >
                        <td align="center" colSpan="5">
                            <Spinner color='green' />
                        </td>
                    </tr>)
                : null
            }
            {Object.entries(groups).map(([name, docs]) => (
                <DocRows
                    key={name}
                    project={project}
                    docs={docs}
                />
            ))}
        </tbody>
    );
};
