import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Provider, useSelector, useDispatch } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import {
    createBrowserRouter,
    NavLink,
    Link,
    Navigate,
    Outlet,
    RouterProvider,
    useNavigate,
    useLocation,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import store, * as storeExt from './store';
import { WithHubConnectionContext, useHubConnection } from './hubConnection';
import {
    HorizontalLine,
    Modal,
    InitialsButton,
    Alert as RawAlert,
    // Table,
    // TableRow,
} from './components';
import { Project } from './pages/project';
import { Document } from './pages/document';
import { User } from './pages/user';
import { NewProjectModal, CpqModal, WaitingModal, DeleteProjectModal } from './modals';
// import { useTitle, useSidebarContent } from './utils';

import { ReactComponent as VibroAcousticsLogo } from './assets/svgs/vibro-acoustics-by-swegon.svg';
import editIcon from './assets/svgs/edit-b.svg';
import duplicateIcon from './assets/svgs/duplicate-b.svg';
import deleteIcon from './assets/svgs/trash-can-b.svg'
// import { ReactComponent as SwegonLogo } from './assets/svgs/logo.svg';
// import { ReactComponent as XIcon } from './assets/svgs/x.svg';
// import { ReactComponent as InfoIcon } from './assets/svgs/info.svg';
// import { ReactComponent as IconButtonProject } from './assets/svgs/icon-button-project.svg';
// import { ReactComponent as ViewTile } from './assets/svgs/view-tile.svg';
import { ChevronDownIcon, ViewListIcon, AddIcon } from './swegon-compat/icons';

const { useGetMeQuery, useUpdateMySettingsMutation, useGetProjectsQuery, useCopyProjectMutation, mainActions, } =
    storeExt;

const Alert = ({ id, kind, msg }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            dispatch(storeExt.mainActions.clearAlert(id));
        }, 5000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [dispatch, id]);

    return (
        <RawAlert
            kind={kind}
            msg={msg}
            onClose={() => dispatch(storeExt.mainActions.clearAlert(id))}
        />
    );
};

const Alerts = () => {
    const alerts = useSelector((state) => state.main.alerts);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (location.state) {
            const { alert, ...restState } = location.state;
            const state = Object.keys(restState).length > 0 ? restState : null;
            if (alert) {
                dispatch(storeExt.mainActions.addAlert(alert));
                navigate(location.pathname, { replace: true, state });
            }
        }
    }, [location.state, location.pathname, dispatch, navigate]);

    return alerts.map((alert) => (
        <Alert
            key={alert.id}
            {...alert}
        />
    ));
};

const UserMenu = (props) => {
    const { data: currentUser } = useGetMeQuery();
    const [menuHidden, setMenuHidden] = React.useState(true);
    const signOutFormRef = React.useRef();
    const navigate = useNavigate();

    return (
        <>
            <OutsideClickHandler
                onOutsideClick={() => {
                    if (!menuHidden) setMenuHidden(true);
                }}
            >
                <div className="relative">
                    <InitialsButton
                        onClick={() => {
                            setMenuHidden(!menuHidden);
                        }}
                    >
                        {currentUser.initials}
                    </InitialsButton>
                    <div
                        className={classNames(
                            'absolute w-max right-0 top-10 z-10',
                            { hidden: menuHidden },
                        )}
                    >
                        <div className="bg-white shadow-swegon3 w-fit rounded-swegonSmall p-4 border-none min-w-[15%]">
                            <div className="text-lg font-bold">
                                {currentUser.name}
                            </div>
                            <div className="text-sm text-black-75">
                                {currentUser.email}
                            </div>
                            <HorizontalLine />
                            <div
                                className="py-1 text-black-75 cursor-pointer flex flex-row items-center mt-1 hover:cursor-pointer hover:bg-green-secondary"
                                onClick={() => {
                                    navigate('/settings', { replace: true });
                                    setMenuHidden(true);
                                }}
                            >
                                <div className="w-8 flex justify-center justify-items-center">
                                    <ViewListIcon />
                                </div>
                                <div>Setttings</div>
                            </div>
                            <div
                                onClick={() => signOutFormRef.current.submit()}
                                className="py-1 text-black-75 cursor-pointer flex flex-row items-center mt-1 hover:cursor-pointer hover:bg-green-secondary"
                            >
                                <div className="w-8 flex justify-center justify-items-center">
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" />
                                </div>
                                <div>Sign out</div>
                            </div>
                        </div>
                    </div>
                </div>
            </OutsideClickHandler>
            <form
                ref={signOutFormRef}
                className="hidden"
                method="POST"
                action="/api/sign-out"
            />
        </>
    );
};

const PageLayout = () => {
    const { data: currentUser, isError: getMeIsError } = useGetMeQuery();
    const { projectId } = useParams();
    const projectDocRoute =
        projectId === null || projectId === undefined
            ? '/documents'
            : `/documents/${projectId}`;
    const analysisRoute =
        projectId === null || projectId === undefined
            ? '/projects'
            : `/projects/${projectId}`;
    const usersRoute = '/users';
    const canEditUsers = currentUser?.roles.find((x) => { return x.code === 'EDIT_USERS' }) ?? false;
    // console.log(projectDocRoute);
    return (
        <>
            <header className="sticky top-0 bg-white">
                <nav className="px-4 py-1 border-b border-green-secondary">
                    <div className="flex flex-wrap justify-between items-center mx-auto">
                        <div className="flex flex-wrap justify-between items-center">
                            <a
                                className="mr-8"
                                href="https://swegon.com"
                            >
                                <VibroAcousticsLogo />
                            </a>
                            <div>
                                <div className="flex flex-wrap justify-between">
                                    <NavLink
                                        to="/"
                                        className={({ isActive }) =>
                                            `leading-7 font-bold text-lg px-4 py-2 hover:bg-green-secondary mr-2${
                                                isActive
                                                    ? ' text-green-dark underline decoration-4 underline-offset-8'
                                                    : ' text-black-75'
                                            }`
                                        }
                                    >
                                        Home
                                    </NavLink>
                                    <NavLink
                                        to={analysisRoute}
                                        className={({ isActive }) =>
                                            `leading-7 font-bold text-lg px-4 py-2 hover:bg-green-secondary mr-2${
                                                isActive
                                                    ? ' text-green-dark underline decoration-4 underline-offset-8'
                                                    : ' text-black-75'
                                            }`
                                        }
                                    >
                                        Analysis
                                    </NavLink>
                                    <NavLink
                                        to={projectDocRoute}
                                        className={({ isActive }) =>
                                            `leading-7 font-bold text-lg px-4 py-2 hover:bg-green-secondary mr-2${
                                                isActive
                                                    ? ' text-green-dark underline decoration-4 underline-offset-8'
                                                    : ' text-black-75'
                                            }`
                                        }
                                    >
                                        Documents
                                    </NavLink>
                                    { canEditUsers
                                        ? (<NavLink
                                                to={usersRoute}
                                                className={({ isActive }) =>
                                                    `leading-7 font-bold text-lg px-4 py-2 hover:bg-green-secondary mr-2${
                                                        isActive
                                                            ? ' text-green-dark underline decoration-4 underline-offset-8'
                                                            : ' text-black-75'
                                                    }`
                                                }
                                            >
                                                Users
                                            </NavLink>
                                          )
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            {currentUser ? (
                                <UserMenu />
                            ) : (
                                <a
                                    href="/api/sign-in"
                                    className="text-black-75 hover:bg-black-10 focus:ring-2 focus:ring-black-10 rounded-swegonSmall px-4 py-2 mr-2"
                                >
                                    Sign in
                                </a>
                            )}
                        </div>
                    </div>
                </nav>
            </header>
            <div>{/* <SwegonButton>HJ</SwegonButton> */}</div>
            <div className="w-full flex flex-col sm:flex-row flex-grow overflow-hidden">
                <main
                    role="main"
                    className="w-full h-full flex-grow p-4 overflow-auto bg-black-5"
                >
                    <Alerts />
                    <Outlet />
                    <WaitingModal />
                </main>
            </div>
            <footer className="px-8 py-4 w-full border-y border-green-secondary">
                <div className="flex flex-wrap justify-between items-center mx-auto">
                    <div>
                        &copy; {new Date().getFullYear()} Swegon North America
                    </div>
                    <div>
                        <a href="mailto:jacob.brown@swegon.com">Contact us</a>
                    </div>
                </div>
            </footer>
        </>
    );
};

const Home = () => {
    const { data: currentUser, isError: getMeIsError } = useGetMeQuery();

    const hasAuthenticated = currentUser || getMeIsError;

    if (currentUser) {
        return <ProjectsAll />;
        /*
            <Navigate
                to="/projects"
                replace={true}
            />
        );
        */
    }

    if (!hasAuthenticated) return null;

    return (
        <>
            <h1>VIRS Project Design</h1>
            <p>
                Please{' '}
                <a
                    className="underline text-blue-100 hover:text-blue-75"
                    href="/api/sign-in"
                >
                    sign in
                </a>{' '}
                to continue
            </p>
        </>
    );
};

const Projects = () => <Outlet />;

const ProjectsAll = () => {
    // const currentUser = useSelector(state => state.main.currentUser);
    const { data: projects } = useGetProjectsQuery();
    const [showNewProjectModal, setShowNewProjectModal] = React.useState(false);
    const navigate = useNavigate();

    const [selectedProject, setSelectedProject] = React.useState(null);
    const [copyProject] = useCopyProjectMutation();
    const dispatch = useDispatch();

    const handleCopyProject = async (projectId) => {
        let res = null;
        dispatch(mainActions.showWaitingModal({
            isSimple: true,
            msg: 'Copying Project...Please wait...',
        }),);
        try {
            res = await copyProject({ id: projectId }).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(mainActions.hideWaitingModal());
            navigate(`/projects/${res.id}`);
        }                
    }

    return (
        <>
            <div className="lg:container bg-white border border-black-10 p-8">
                <div className="flex items-center justify-between">
                    <h1>VIRS Project Design</h1>
                    <button
                        onClick={() => setShowNewProjectModal(true)}
                        className="flex justify-items-center rounded-swegonSmall bg-black-10 p-2 mb-4"
                    >
                        <div className="pr-2">
                            <AddIcon />
                        </div>
                        <div>New Project</div>
                    </button>
                </div>

                <table className="swegon-ui-table bordered hovered">
                    <thead>
                        <tr>
                            <th>Actions</th>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Created</th>
                            <th>Updated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {projects && projects.length > 0 ? (
                            projects.map((project) => (
                                <tr
                                    key={project.id}
                                    
                                >
                                    <td>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <button style={{
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                                marginRight: '8px'
                                            
                                            }}
                                            onClick={() => {
                                                navigate(`/projects/${project.id}`);
                                            }}
                                        >
                                            <img
                                                src={editIcon}
                                                alt="Select"
                                                style={{
                                                width: '20px',
                                                height: '20px',
                                                
                                                }}
                                            />
                                        </button>
                                        <button style={{
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                                marginRight: '8px'
                                            }}
                                            onClick={() => handleCopyProject(project.id)}
                                        >
                                            <img
                                                src={duplicateIcon}
                                                alt="Select"
                                                style={{
                                                width: '20px',
                                                height: '20px',
                                                
                                                }}
                                            />
                                        </button>
                                        <button style={{
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                                marginRight: '8px'
                                            }}
                                            onClick={() => setSelectedProject(project)}
                                        >
                                            <img
                                                src={deleteIcon}
                                                alt="Select"
                                                style={{
                                                width: '20px',
                                                height: '20px',
                                                
                                                }}
                                            />
                                        </button>
                                    </div>
                                    </td>                                      
                                    <td>{project.shortId}</td>
                                    <td>{project.name}</td>
                                    <td>
                                        {new Date(
                                            project.createdAt,
                                        ).toLocaleString('en-US')}
                                    </td>
                                    <td>
                                        {new Date(
                                            project.updatedAt,
                                        ).toLocaleString('en-US')}
                                    </td>

                                </tr>
                                
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4}>No projects</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <NewProjectModal
                show={showNewProjectModal}
                onClose={() => setShowNewProjectModal(false)}
            />
            <DeleteProjectModal
                project={selectedProject}
                onClose={() => setSelectedProject(null)}
            />
            <WaitingModal/>
        </>
    );
}

const Documents = () => <Outlet />;

const DocumentsAll = () => <Outlet />;

const Users = () => <Outlet />;

const AuthRoute = () => {
    const { data: currentUser, isError: getMeIsError } = useGetMeQuery();
    const hasAuthenticated = currentUser || getMeIsError;

    const outletContext = useOutletContext();

    if (hasAuthenticated && !currentUser) {
        return (
            <Navigate
                to="/"
                replace={true}
                state={{ alert: { kind: 'error', msg: 'Please sign in' } }}
            />
        );
    }

    return <Outlet context={outletContext} />;
};

const Settings = () => {
    const { data: currentUser } = useGetMeQuery();

    const [updateMySetttings, _updateMySettingsResult] =
        useUpdateMySettingsMutation();

    // const dispatch = useDispatch();

    // if (!currentUser) return null;

    const initialValues = {
        unitType:
            currentUser?.settings?.find((x) => x.key === 'unitType')?.value ??
            'imperial',
        language:
            currentUser?.settings?.find((x) => x.key === 'language')?.value ??
            'en',
    };

    const handleSubmit = (values, formikHelpers) => {
        updateMySetttings(values);
        formikHelpers.setSubmitting(false);
    };

    return (
        <>
            <h1>Settings</h1>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="mb-4">
                                <label
                                    className="block mb-2 text-sm font-medium"
                                    htmlFor="unitType"
                                >
                                    Unit Type
                                </label>
                                <select
                                    name="unitType"
                                    onChange={formik.handleChange}
                                    value={formik.values.unitType}
                                >
                                    <option value="imperial">Imperial</option>
                                    <option value="metric">Metric</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label
                                    className="block mb-2 text-sm font-medium"
                                    htmlFor="language"
                                >
                                    Language
                                </label>
                                <select
                                    name="language"
                                    onChange={formik.handleChange}
                                    value={formik.values.language}
                                >
                                    <option value="en">English</option>
                                    <option value="sv">Swedish</option>
                                </select>
                            </div>
                            <button
                                className="text-white bg-blue-75 hover:bg-blue-100 focus:ring-4 focus:ring-blue-10 font-medium rounded-swegonSmall text-sm px-5 py-2.5 mr-2 mb-2"
                                type="submit"
                                disabled={formik.isSubmitting}
                            >
                                Submit
                            </button>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    );
};

const router = createBrowserRouter([
    {
        path: '/',
        element: <PageLayout />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                element: <AuthRoute />,
                children: [
                    {
                        path: 'settings',
                        element: <Settings />,
                    },
                    {
                        path: 'projects',
                        element: <Projects />,
                        children: [
                            /*
                            {
                                index: true,
                                element: <ProjectsAll />,
                            },
                            */
                            // {
                            //     path: "new",
                            //     element: <NewProject/>
                            // },
                            {
                                path: ':projectId',
                                element: <Project />,
                            },
                        ],
                    },
                    {
                        path: 'documents',
                        element: <Documents />,
                        children: [
                            {
                                index: true,
                                element: <DocumentsAll />,
                            },
                            {
                                path: ':projectId',
                                element: <Document />,
                            },
                        ],
                    },
                    {
                        path: 'users',
                        element: <User />,
                    },
                ],
            },
        ],
    },
]);

const App = () => {
    return (
        <WithHubConnectionContext opts={{ url: '/hubs/main' }}>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </WithHubConnectionContext>
    );
};

export default App;
