// const defaultTheme = require('tailwindcss/defaultTheme');

const swegonCompat = require('./src/swegon-compat/common');

module.exports = {
    content: ['./src/**/*.js', 'public/index.html'],
    theme: {
        extend: {
            borderRadius: {
                swegonSmall: swegonCompat.Layout.CORNER_RADIUS_SMALL,
                swegonRound: swegonCompat.Layout.CORNER_RADIUS_ROUND,
            },
            boxShadow: {
                swegon1: swegonCompat.Elevation.ElevationShadow1,
                swegon2: swegonCompat.Elevation.ElevationShadow2,
                swegon3: swegonCompat.Elevation.ElevationShadow3,
                swegonInner: swegonCompat.Elevation.InnerShadow,
            },
            fontFamily: {
                sans: ['SwegonBrown', 'sans-serif'],
            },
        },
        colors: {
            transparent: 'transparent',
            current: 'currentColor',
            white: swegonCompat.Colors.WHITE,
            black: {
                90: swegonCompat.Colors.BLACK_90,
                75: swegonCompat.Colors.BLACK_75,
                50: swegonCompat.Colors.BLACK_50,
                30: swegonCompat.Colors.BLACK_30,
                20: swegonCompat.Colors.BLACK_20,
                10: swegonCompat.Colors.BLACK_10,
                5: swegonCompat.Colors.BLACK_5,
                2: swegonCompat.Colors.BLACK_2,
            },
            purple: {
                100: swegonCompat.Colors.PURPLE_100,
                75: swegonCompat.Colors.PURPLE_75,
            },
            green: {
                primary: swegonCompat.Colors.PRIMARY_GREEN,
                secondary: swegonCompat.Colors.SECONDARY_GREEN,
                dark: swegonCompat.Colors.DARK_GREEN,
            },
            blue: {
                100: swegonCompat.Colors.BLUE_100,
                75: swegonCompat.Colors.BLUE_75,
                50: swegonCompat.Colors.BLUE_50,
                25: swegonCompat.Colors.BLUE_25,
                10: swegonCompat.Colors.BLUE_10,
                5: swegonCompat.Colors.BLUE_5,
            },
            red: {
                100: swegonCompat.Colors.RED_100,
                75: swegonCompat.Colors.RED_75,
                50: swegonCompat.Colors.RED_50,
                25: swegonCompat.Colors.RED_25,
                10: swegonCompat.Colors.RED_10,
                5: swegonCompat.Colors.RED_5,
            },
            amber: {
                100: swegonCompat.Colors.AMBER_100,
                75: swegonCompat.Colors.AMBER_75,
                50: swegonCompat.Colors.AMBER_50,
                25: swegonCompat.Colors.AMBER_25,
                10: swegonCompat.Colors.AMBER_10,
                5: swegonCompat.Colors.AMBER_5,
            },
            yellow: {
                100: swegonCompat.Colors.YELLOW_100,
                75: swegonCompat.Colors.YELLOW_75,
                50: swegonCompat.Colors.YELLOW_50,
                25: swegonCompat.Colors.YELLOW_25,
                10: swegonCompat.Colors.YELLOW_10,
                5: swegonCompat.Colors.YELLOW_5,
            },
            gray: {
                100: swegonCompat.Colors.BLACK_90,
                75: swegonCompat.Colors.BLACK_75,
                50: swegonCompat.Colors.BLACK_50,
                30: swegonCompat.Colors.BLACK_30,
                10: swegonCompat.Colors.BLACK_10,
                5: swegonCompat.Colors.BLACK_5,
            },
        },
    },
    // plugins: [require("@tailwindcss/forms")],
};
